.teaser {
	&--text-top,
	&--text-bottom {
		.cmp-teaser {
			position: initial;
			flex-direction: column;
			
			&__link {
				position: initial;
				flex-direction: column;
			}

			&__image {
				position: relative;
				display: block;
				width: 100%;
				height: 14rem;

				& .cmp-image {
					margin: 0;
				}

				& img {
					position: absolute;
				}
			}

			&__content {
				position: initial;
				padding: 1rem;
			}


			&__action {
				&-container {
					margin-bottom: 0;
				}
			}
		}
	}

	&--text-bottom {
		.cmp-teaser {
			flex-direction: column-reverse;

			&__link {
				flex-direction: column-reverse;
			}
		}

		&.teaser--more-margin {
			.cmp-teaser {
				padding: 0 8px;

				&__image {
					padding: 18px 18px 0;
					width: auto;
					height: 17rem;

					@media screen and (max-width: 768px) {	
    					height: 30rem;
					} 

					img {
						border-radius: 10px;

						@media screen and (max-width: 768px) {	
							max-height: none;
						} 
					}
				}
				&__title,
				&__description {

					@media screen and (max-width: 1254px) {	
    					word-break: break-word;
						hyphens: auto;
						width: 100%;
					} 
				}
			}

			&.teaser--background {
				&-teal {
					.cmp-teaser {
						&__image {
							background-color: $color-link-light;
						}
					}
				}
				&-grey {
					.cmp-teaser {
						&__image {
							background-color: $color-light-background;
						}
					}
				}
				&-red  {
					.cmp-teaser {
						&__image {
							background-color: $color-accent;
						}
					}
				}
			}
		}
	}
}