.facet {
    margin-top: 1em;
}

.facet+.facet {
    margin-top: 1.5em;
}

.cmp-facet {
    +.cmp-facet {
        margin-top: 1.5em;
    }

    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    background: transparent;

    &__title {
        margin: 0 0 .5em 0;
        padding: 0;
        border: 0;
        outline: 0;
        font-size: 100%;
        background: transparent;
        font-size: 1rem;
        font-weight: bold;
        color: $color-text;
    }

    &__checkbox {
        cursor: pointer;
        display: block;
        font-size: 16px;
        line-height: 20px;
        align-self: flex-start;
        display: flex;
        position: relative;
        padding: 4px;
        padding-left: 30px;
        border: 1px dotted transparent;
        margin-bottom: 8px;
        color: $color-text;
        font-size: .875rem;
        border-radius: .25rem;

        @media screen and (min-width:$tablet) {
            padding-left: 36px;
            line-height: 24px;
        }

        &:focus-within {
            border: 1px dashed $color-accent-lighter;
        }

        &:hover {
            .cmp-facet__checkbox__label {
                color: $color-shade-3;
            }

            .cmp-facet__checkbox__indicator {
                border: 1px solid $color-link-dark;
            }

        }

        &__input {
            position: absolute;
            z-index: -1;
            opacity: 0;

            &:checked~.cmp-facet__checkbox {}

            &:checked~.cmp-facet__checkbox__label {
                color: $color-shade-3;
            }

            &:checked~.cmp-facet__checkbox__indicator {
                background: $color-link-dark;
                border: 1px solid $color-link-dark;

                >svg {
                    color: #fff;
                    opacity: 1;
                }
            }

        }

        &__indicator {
            box-sizing: border-box;
            position: absolute;
            left: 4px;
            top: 0;
            height: 20px;
            width: 20px;
            padding: 3px;
            background-color: transparent;
            border: 1px solid #ccc;
            border-radius: 4px;
            margin: 4px auto auto;
            transition: border .15s linear, background-color .15s linear;

            @media screen and (min-width:$tablet) {
                height: 24px;
                width: 24px;
            }



            >svg {
                display: block;
                opacity: 0;
                width: 12px;
                height: 12px;

                @media screen and (min-width:$tablet) {
                    width: 16px;
                    height: 16px;
                }

            }

        }

        &__label {
            transition: color .15s linear;
        }
    }

}