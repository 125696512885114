.cmp-text {
	margin: 0.75rem 0 0 0;
	color: $color-text;
	font-weight: normal;
	font-size: 1em;

	& b {
		font-weight: bold;
	}

	& p a {
		text-decoration-thickness: 2px !important;
		text-decoration: underline;
		text-decoration-color: $color-link;
		transition: 0.7s;

		&:hover {
			text-decoration-color: $color-accent;
		}

		&:focus {
			text-decoration: underline;
   			outline: $color-accent-lighter solid 2px;
			text-decoration-color: $color-accent;
			outline-offset: 1px;
		}
	}

	& blockquote {
		margin: 0.75rem 0;
		font-size: 1.75em;
		font-family: titleFont, serif;
		font-style: italic;
		line-height: 2.375rem;
		margin-inline: 0;
	}

	ul {
		list-style-type: none;
		
		li {
			position: relative;

			&::before {
				content: "\2022";
				color: $color-link;
				display: inline-block;
				font-weight: normal;
				width: 2em;
				position: absolute;
				top: 0;
				left: -24px;
			}
		}
	}

	ol {
		li {

			&::marker {
				color: $color-link;
			}
		}
	}

	ul, 
	ol {
		li {
			padding-bottom: 0.5em;

			a {
				text-decoration-color: transparent;

				&:hover {
					text-decoration-color: $color-accent;
				}
			}
		}
	}
}