.horizontal {
  .cmp-tabs {}

  .cmp-tabs__tablist {
    border-bottom: 0.125em solid $color-shade-1;
  }
  .cmp-tabs__tab {

    border-bottom: 0.125em solid transparent;
	transition: 0.3s;
  }
  .cmp-tabs__tab--active {
    border-color: $color-accent;
    &:focus {
		border-color: $color-accent;
    }
  }

  .cmp-tabs__tabpanel {}
  .cmp-tabs__tabpanel--active {}
}