
//== Font

$font-family: Graphik, Calibri, sans-serif;
$font-size: 18px;

//== Color

$color-background: #ffffff;
$color-light-background: #F8F8F8;
$color-foreground: #000000;
$color-link-light: #e2f3f7;
$color-link: #4f9eb2; //FATF
$color-link-hover: #3e7b8b; //FATF
$color-link-dark: #267589; 
$color-link-darker: #227185; 
$color-link-darkest: #105F73; 
$color-accent: #DF3327; //FATF
$color-accent-lighter: #ED9C75; //FATF
$color-accent-dark: #b72a20; //FATF
$color-accent-darker: #92140B; //FATF
$color-light-grey: #F3F3F5;
$color-mid-grey: #54717F; //FATF
$color-light-grey: #ccc;
$color-shade-1: #eceff1;
$color-shade-2: #cfd8dc;
$color-shade-3: #5d7a88;
$color-shade-4: #455a64;
$color-black: #13181b;
$color-lighter-black: #23282B;

$error: #d7373f;
$error-darker: #be1e26;
$success: #268e6c;
$success-darker: #0d7553;

$color-text: #333333; //default text color
$color-text-white: $color-background;
$color-text-inverted: $color-background;

$color-shadow: rgba(0, 0, 0, 0.16);
$color-transparent: rgba(0, 0, 0, 0);

$color-accent-a5: #df33270D; 
$color-link-a15: #4f9eb226; 
$color-link-a25: #4f9eb240; 
$color-white-a15: #ffffff26; 

//== Breakpoints
$small-mobile: 320px;
$mobile: 500px;
$tablet: 998px;
$tablet-large: 1190px;

