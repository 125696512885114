.cmp-download {
	height: fit-content;
	margin: 0.5em 0;
	padding: 1.5em;
	border: 0.125em solid $color-link;
	border-radius: 0.25em;
	position: relative;

	&__title {
		margin-bottom: 1.5rem;

		&-link {
			color: $color-text;
			font-weight: normal;
			font-size: 1.125rem;
			text-decoration: none;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}
	}

	&__description {
		color: $color-text;
		font-weight: lighter;
		font-size: 1em;
		margin-bottom: 2rem;
	
		& p {
			margin-block: 0;
			margin-top: 0.625rem;
		}
	}

	&__properties {
		display: flex;
		color: $color-shade-3;
		font-weight: normal;
		font-size: 0.625em;
		float: right;
		margin-bottom: 1rem;

		@media screen and (max-width: 768px) {
			width: 100%;
		}
	
		& div {
			float: left;
		}
	}

	&__property {
		bottom: 20px;
		right: 27px;
		
		&-label {
			display: none;
		}

		&-content {
			margin: 0 0.625em 0 0;
			font-size: 14px;
		}

		&--filename {
			display: none;
		}

		&--size {
			order: 2;
		}

		&--format {
			order: 1;
			text-transform: uppercase;
		}
	}

	&__action {
		padding: 0.5rem 1.5rem 0.5rem 1rem;
		text-decoration: none;
		border-radius: 1.56em;
		border: 2px solid $color-link-dark;
		background-color: $color-background;
		transition: 0.5s;

		&:hover, &:active {
			border-color: $color-link-dark;
		}

		&:hover {
			background-color: $color-link-a15;
		}

		&:active {
			background-color: $color-link-a25;
		}

		&:focus {
			box-shadow: none;
			outline: none;
		}
	
		&:focus-visible {
			background-color: $color-accent-lighter;
			border: 0.125em solid $color-background;
			border-radius: 1.56em;
			outline: none;
			box-shadow: 0 0 0 0.125em $color-accent-lighter;
		}

		&-text {
			color: $color-text;
			white-space: nowrap;
			overflow: hidden;
			position: relative;
		
			&:before {
				display: inline-block;
				width: 24px;
				height: 24px;
				margin-right: 0.625em;
				vertical-align: -25%;
				background-color: $color-text;
				content: '';
				mask: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48ZGVmcz48c3R5bGU+LmEsLmJ7ZmlsbDojZmZmO30uYXtvcGFjaXR5OjA7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC01NjUgLTgwKSI+PHJlY3QgY2xhc3M9ImEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNTY1IDgwKSIvPjxwYXRoIGNsYXNzPSJiIiBkPSJNNC4yOTMsMTMuMTIyYTEsMSwwLDAsMSwwLTEuNDE0TDguMTcyLDcuODI5SC0yLjE3MWExLDEsMCwwLDEtMS0xLDEsMSwwLDAsMSwxLTFIOC4zMTNsLTQuMDItNC4wMkExLDEsMCwwLDEsNS43MDcuMzk0TDExLjM0LDYuMDI2bC4wMjQuMDI0YTEsMSwwLDAsMSwwLDEuNDE1TDUuNzA3LDEzLjEyMmExLDEsMCwwLDEtMS40MTQsMFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDU4My44MjkgODguMTcxKSByb3RhdGUoOTApIi8+PC9nPjwvc3ZnPg==');
				mask-size: cover;
			}
		}
	}
}