.cmp-carousel {
	&__item {
		@media screen and (max-width: $mobile) {
			height: 600px;
			margin-bottom: 16px;
		}
		
		@media screen and (min-width: $mobile) and (max-width: $tablet) {
			height: 550px;
			margin-bottom: 16px;
		}
	}
}

.cmp-carousel__content {
	position: relative;

	.teaser {
		height: 100%;

		.cmp-teaser {
			height: 100%;
			margin-top: 0;

			&__image {
				height: 100%;
				position: relative;

				.cmp-image {
					height: 100%;

					img {
						max-height: 600px;
					}
				}
			}
			
			&__content {
				position: absolute;
				margin-left: 15%;
				margin-right: 15%;
				padding: 0;

				@media screen and (max-width: $tablet) {
					margin-left: 10%;
					margin-right: 10%;
				}
			}

			&__title {
				display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}

			&__description {
				p {
					display: -webkit-box;
					-webkit-line-clamp: 4;
					-webkit-box-orient: vertical;
					overflow: hidden;
					@media screen and (max-width: $tablet) {
						-webkit-line-clamp: 5;
						margin-top: 0;
					}
				}
			}

			&__action {
				&-link {
					&:after {
						top: 4px;
					}
				}
			}

			.cmp-teaser__description + .cmp-teaser__action-container {
				@media screen and (min-width: $mobile) and (max-width: $tablet) {
					margin-bottom: 60px;
				}
			
				@media screen and (max-width: $mobile) {
					margin-bottom: 65px;
				}
			}
		}
	}
}

.cmp-carousel__actions {
	position: absolute;
	right: 16px;
	bottom: 50px;
	display: flex;
	flex-direction: row;
	float: right;

	@media screen and (max-width: $tablet) {
		right: 0;
		left: 0;
		margin-left: auto;
		margin-right: auto;
		justify-content: space-between;
	}

	@media screen and (min-width: 601px) and (max-width: 998px) {
		width: 50%;
	}

	@media screen and (max-width: 600px) {
		width: 85%;
	}
}

.cmp-carousel__action {
	position: relative;
	width: 2.5rem;
	height: 2.5rem;
	margin: 0.125em;
	background-color: $color-shade-1;
	border-width: 0;
	border-radius: 0.25em;
	box-shadow: none;
	cursor: pointer;

	& .cmp-carousel__action-icon {
		&:before {
			position: absolute;
			top: 50%;
			left: 50%;
			display: inline-block;
			width: 24px;
			height: 24px;
			background-color: $color-shade-3;
			transform: translate(-50%, -50%);
			content: '';
			mask-size: cover;
		}
	}

	&:focus {
		outline: none;
	}
}

.cmp-carousel__action--previous {
	& .cmp-carousel__action-icon {
		&:before {
			mask: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48ZGVmcz48c3R5bGU+LmEsLmJ7ZmlsbDojNjA3ZDhiO30uYXtvcGFjaXR5OjA7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00ODUgLTgwKSI+PHJlY3QgY2xhc3M9ImEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDg1IDgwKSIvPjxwYXRoIGNsYXNzPSJiIiBkPSJNLjI5MywxMy4wMjFhMSwxLDAsMCwxLDAtMS40MTVsNC45NS00Ljk1TC4yOTMsMS43MDdBMSwxLDAsMCwxLDEuNzA3LjI5M0w3LjM2NCw1Ljk1YTEsMSwwLDAsMSwwLDEuNDE0TDEuNzA3LDEzLjAyMWExLDEsMCwwLDEtMS40MTUsMFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDUwMSA5OC42NTcpIHJvdGF0ZSgxODApIi8+PC9nPjwvc3ZnPg==');
		}
	}
}

.cmp-carousel__action--next {
	& .cmp-carousel__action-icon {
		&:before {
			mask: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48ZGVmcz48c3R5bGU+LmEsLmJ7ZmlsbDojNjA3ZDhiO30uYXtvcGFjaXR5OjA7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00NDUgLTgwKSI+PHJlY3QgY2xhc3M9ImEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDQ1IDgwKSIvPjxwYXRoIGNsYXNzPSJiIiBkPSJNMzAuNzA3LTMuNTY1YTEsMSwwLDAsMSwwLTEuNDE0bDQuOTQ5LTQuOTUtNC45NDktNC45NWExLDEsMCwwLDEsMC0xLjQxNCwxLDEsMCwwLDEsMS40MTQsMGw1LjY1Nyw1LjY1NmExLDEsMCwwLDEsLjI5My43MDcsMSwxLDAsMCwxLS4yOTMuNzA3TDMyLjEyMS0zLjU2NWExLDEsMCwwLDEtLjcwNy4yOTNBMSwxLDAsMCwxLDMwLjcwNy0zLjU2NVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQyMi45MjkgMTAxLjkyOSkiLz48L2c+PC9zdmc+');
		}
	}
}

.cmp-carousel__action--pause {
	display: none;
}

.cmp-carousel__action--play {
	display: none;
}

.cmp-carousel__action-text {
	display: none;
}

.cmp-carousel__indicators {
	height: 20px;
	bottom: 50px;
    position: relative;
}

.cmp-carousel__indicator {
	width: 2.5rem;
	height: 8px;
	margin: 0 8px;
	border-radius: 5.125rem;
	background-color: $color-shade-1;
	cursor: pointer;
	box-shadow: 2px 3px 8px 1px rgba(0,0,0,0.45);

	&:hover {
		background-color: $color-accent-lighter;
	}

	&:before {
		content: "";
		position: absolute;
		left: 0;
		top: -25px;
		height: 45px;
		width: 45px;
	}
}

.cmp-carousel__indicator--active {
	background-color: $color-accent;
}
