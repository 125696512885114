.cmp-search-results__list {
    margin: 0;
    padding: 0;

}

.cmp-search-results__list-item {
    position: relative;
    width: 100%;
    margin: 16px 0 16px;
    padding: 0;
    list-style: none;

}

.cmp-search-results__list-item+.cmp-search-results__list-item {
    border-top: 1px solid $color-shade-1;
}

.cmp-search-results {
    &__order-bar {
        border-bottom: 2px solid $color-shade-1;
        padding: 0 0 1rem;
        margin: 0 0 1.5rem;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        color: $color-mid-grey;

        &__amount {
            margin-right: 1em;
            font-size: .875rem;
        }

        &__order {
            font-size: .875rem;
        }
    }

    &__order {
        &:focus {
            outline-offset: 1px;
            outline: $color-accent-lighter solid 2px;
        }
    }

    &__result {
        position: relative;
        margin: 16px 0;
        display: flex;
        align-items: flex-start;
        width: 100%;


        &:before {
            content: "";
            display: block;
            background-color: $color-shade-1;
            width: calc(100% + 16px);
            height: calc(100% + 16px);
            position: absolute;
            left: -8px;
            top: -8px;
            opacity: 0;
            z-index: 0;
            transition: opacity .15s linear;
        }

        &:hover {
            .cmp-search-results__result__title a {
                color: $color-link-darker;
            }
        }

        &:focus-within {
            outline-offset: 2px;
            outline: $color-accent-lighter solid 2px;
        }

        &__visual {
            flex: 0 0 100px;
            height: 100px;
            margin-right: 24px;
            overflow: hidden;
        }
        
        &__image {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: top center;
        }

        &__content {
            flex: 1 0 auto;
            width: 20px;
            padding: 8px 0;
        }

        &__description {
            font-size: 14px;
            margin: .25rem 0 0;
            position: relative;
            z-index: 1;

        }

        &__date {
            line-height: 1;
            font-size: 12px;
            color: $color-shade-4;
            position: relative;
            margin: 8px 0 0 0;
            z-index: 1;
        }

        &__title {
            margin: 0;
            line-height: 1.2;
            font-size: 1.125rem;
            font-weight: 400;

            a {
                display: block;
                color: $color-link;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;

                }

                &:focus {
                    outline: none;
                    text-decoration: underline;
                }

                &::before {
                    position: absolute;
                    content: "";
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    z-index: 2;

                }
            }
        }
    }

    &__message {
        font-weight: bold;
        padding: 1em;
        font-size: 16px;
        background-color: $color-light-background;
        border: 1px solid $color-light-grey;
        border-radius: 4px;
        margin: 1.5em 0;
    }

    &__pagination {
        border-top: 2px solid $color-shade-1;
        padding: 1rem 0;
        margin: 1.5rem 0 0;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: .5em;

        &__prev {
            justify-self: flex-start;
        }

        &__next {
            justify-self: flex-end;
            text-align: right;
        }

        &__button {
            background: transparent;
            -webkit-appearance: none;
            border: 0;
            cursor: pointer;
            color: $color-text;
            text-decoration: none;
            border-radius: 4px;
            font-size: 16px;
			text-decoration: underline;

            &:hover {
                color: $color-link-darkest;
            }

            &:focus {
                text-decoration: underline;
                outline-offset: 1px;
                outline: $color-accent-lighter solid 2px;
            }

            &[disabled] {
                display: none;
            }

        }

    }
}