.publication-details {
    .cmp-container {
        text-align: right;
        padding-right: 1em;
        margin-top: 0;

        @media screen and (max-width:768px){
            text-align:left;
                        
        }

        .cmp-title {
            margin-bottom: 0;
            h4 {
                margin-top: 1.2em;
                padding-right: 1em;
            }
        }

        #publication-details-language, 
        #publication-details-country, 
        #publication-details-topic {
            .cmp-title {
                margin-top: 0.5em;
                margin-bottom: 0;

                h6{
                    font-size: 16px;
                }
            }
            .cmp-text {
                margin: 0;
                font-size: 14px;                
                 p{
                     margin: 0;
                 }
            }
        }
    }

}