.cmp-search-bar {

    &__form {
        display: flex;
        flex-wrap: wrap;

        &__wrapper {
            max-width: 80ch;
            border: 1px solid $color-mid-grey;
            overflow: hidden;
            border-radius: .5rem;
            background: #fff;
            display: flex;
            flex: 1 0 auto;
            margin-right: .5em;

            &:focus-within {
                outline-offset: 1px;
                outline: $color-accent-lighter solid 2px;
            }
        }
    }

    &__input {
        flex: 1 0 auto;
        border-radius: .5rem 0 0 .5rem;
        -webkit-appearance: none;
        background: #fff;
        padding: .5rem 1rem;
        border: 1px solid transparent;
        color: $color-mid-grey;

        &:focus {
            outline: none;
        }
    }

    &__submit {
        flex: 0 0 auto;
        width: 60px;
        -webkit-appearance: none;
        appearance: none;
        background: transparent;
        border: none;
        background-color: $color-link-dark;
        border-color: $color-link-dark;
        color: #fff;
        cursor: pointer;

        &__icon {
            width: 30px;
            height: 30px;
            fill: #fff;
            display: inline-block;
            vertical-align: middle;
        }

        &:hover {
            background-color: $color-link-darker;
            border-color: $color-link-darker;
        }

        &:focus {
            background-color: $color-link-darkest;
            border-color: $color-link-darkest;
        }
    }

    &__clear {
        appearance: none;
        -webkit-appearance: none;
        background: transparent;
        color: $color-link;
        cursor: pointer;
        color: $color-link;
        border: 0;
        padding: 8px 0;
        display: inline-block;
        font-size: 16px;
        text-decoration: underline;

        &:hover {
            color: $color-link-darkest;

        }

        &:focus {
            color: $color-link-darkest;
            outline-offset: 1px;
            outline: $color-accent-lighter solid 2px;
        }

        &[disabled] {
            display: none;
        }
    }
}