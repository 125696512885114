.button {
	&--color {
		&-teal {
			.cmp-button {
				background-color: $color-link-dark;
				border-color: $color-link-dark;

				&:hover {
					background-color: $color-link-darker;
					border-color: $color-link-darker;
				}

				&:active {
					background-color:  $color-link-darkest;
					border-color:  $color-link-darkest;
				}
			
				&:focus-visible {
					background-color: $color-link-dark;
					border-color: $color-link-dark;
				}
			}
		}

		&-white {
			.cmp-button {
				background-color: $color-background;
				border-color: $color-background;
				color: $color-text;

				&:hover {
					background-color: $color-background;
					border-color: $color-background;
					color: $color-text;
				}

				&:active {
					background-color: $color-light-grey;
					border-color:  $color-light-grey;
					color: $color-text;
				}
			
				&:focus-visible {
					background-color: $color-background;
					border-color: $color-background;
					color: $color-text;
				}
			}
		}
	}


	&--outline {
		.cmp-button {
			background-color: transparent;

			&__text {
				color: $color-accent;
			}

			&:hover, &:active {
				border-color: $color-accent;
			}

			&:hover {
				background-color: transparent;
			}

			&:active {
				background-color: $color-accent-a5;
			}
		}

		&.button {
			&--color {
				&-teal,
				&-white {
					.cmp-button {
						&:hover {
							background-color: transparent;
						}
					}
				}

				&-teal {
					.cmp-button {
						background-color: transparent;

						&__text {
							color:  $color-link-dark;
						}

						&:hover, &:active {
							border-color: $color-link-dark;
						}
		
						&:active {
							background-color: $color-link-a15;
						}
					}
				}
		
				&-white {
					.cmp-button {
						background-color: transparent;

						&__text {
							color: $color-text-white;
						}
		
						&:hover, &:active {
							border-color: $color-text-white;
						}
		
						&:active {
							background-color: $color-white-a15;
						}
					}
				}
			}
		}
	}

	&--subtle {
		.cmp-button {
			background-color: transparent;
			border: none;

			&__text {
				border-bottom: 2px solid $color-accent;
				color: $color-accent;
			}

			&:hover {
				.cmp-button__text {
					padding-right: 15px;

					&:after {
						right: 0px;
					}
				}
			}

			&:active {
				.cmp-button__text {
					color: $color-accent-dark;
					border-color: $color-accent-dark;

					&:after {
						color: $color-accent-dark;
					}
				}
			}
		}

		&.button {
			&--color {
				&-teal,
				&-white {
					.cmp-button {
						&:hover, &:active, &:focus, &:focus-visible {
							background-color: transparent;
						}
					}
				}

				&-teal {
					.cmp-button {
						&__text {
							color:  $color-link-dark;
							border-color: $color-link-dark;
						}

						&:active {
							.cmp-button__text {
								color: $color-link-darker;
								border-color: $color-link-darker;

								&:after {	
									color: $color-link-darker;
								}
							}
						}
					}
				}
		
				&-white {
					.cmp-button {
						&__text {
							color: $color-text-white;
							border-color: $color-text-white;
						}

						&:active {
							.cmp-button__text {
								color: $color-text-white;
								border-color: $color-text-white;

								&:after {	
									color: $color-text-white;
								}
							}
						}
					}
				}
			}
		}
	}

	&--center {
		&.aem-GridColumn.aem-GridColumn--default--none {
			height: 100%;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			padding-right: 15px;
		}
	}
}