.teaser {
	&--text-right,
	&--text-left {
		.cmp-teaser {
			position: static;
			flex-direction: row;

			&__link {
				position: static;
				flex-direction: row;

				@media screen and (max-width: 768px) {
					flex-direction: column-reverse;
					align-items: center;
				}
			}

			@media screen and (max-width: 768px) {
				flex-direction: column-reverse;
				align-items: center;
			}

			&__image {
				width: 300px;

				@media screen and (max-width: 768px) {
					width: 100%;
				}
			
				.cmp-image {
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
				}
			}

			&__content {
				position: static;
				flex: 2;
				padding: 1rem 2rem 1rem 0;
				justify-content: center;

				@media screen and (max-width: 768px) {
					padding: 1rem 0;
				}
			}
		}
	}

	&--text-right {
		.cmp-teaser {
			flex-direction: row-reverse;

			@media screen and (max-width: 768px) {
				flex-direction: column-reverse;
				align-items: center;
			}

			&__link {
				flex-direction: row-reverse;

				@media screen and (max-width: 768px) {
					flex-direction: column-reverse;
					align-items: center;
				}
			}

			&__content {
				padding: 1rem 0 1rem 2rem;
				
				@media screen and (max-width: 768px) {
					padding: 1rem 0;
				}
			}
		}
	}
}