.standard {
	.cmp-breadcrumb {
		margin-top: 3rem;
	}

	.cmp-breadcrumb__list {
		padding-inline-start: 0;
		margin: 0;
	}

	.cmp-breadcrumb__item {

		& a,
		>span {
			padding: 0.5625rem 8px 0.6875rem;
		}
	}

	.cmp-breadcrumb__item:not(:last-of-type) {
		&:after {
			display: inline-block;
			width: 24px;
			height: 24px;
			margin: 0;
			vertical-align: -25%;
			background-color: $color-link;
			content: '';
			pointer-events: none;
			mask: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48ZGVmcz48c3R5bGU+LmEsLmJ7ZmlsbDojNjA3ZDhiO30uYXtvcGFjaXR5OjA7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00NDUgLTgwKSI+PHJlY3QgY2xhc3M9ImEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDQ1IDgwKSIvPjxwYXRoIGNsYXNzPSJiIiBkPSJNMzAuNzA3LTMuNTY1YTEsMSwwLDAsMSwwLTEuNDE0bDQuOTQ5LTQuOTUtNC45NDktNC45NWExLDEsMCwwLDEsMC0xLjQxNCwxLDEsMCwwLDEsMS40MTQsMGw1LjY1Nyw1LjY1NmExLDEsMCwwLDEsLjI5My43MDcsMSwxLDAsMCwxLS4yOTMuNzA3TDMyLjEyMS0zLjU2NWExLDEsMCwwLDEtLjcwNy4yOTNBMSwxLDAsMCwxLDMwLjcwNy0zLjU2NVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQyMi45MjkgMTAxLjkyOSkiLz48L2c+PC9zdmc+');
			mask-size: cover;
		}
	}

	.cmp-breadcrumb__item--active {
		color: $color-text;
		line-height: 1.5rem;
		font-weight: bold;
	}

	.cmp-breadcrumb__item-link {
		color: $color-shade-3;
		text-decoration: none;
		border: 0.125rem solid $color-transparent;
		border-radius: 0.25em;

		span {
			text-decoration: underline;
			text-decoration-color: transparent;
			text-decoration-thickness: 2px;
			transition: 0.5s
		}

		&:hover {
			span {
				text-decoration-color: $color-accent;
			}

			color: $color-text;
		}

		&:focus-visible {
			border-color: $color-accent-lighter;
			border-radius: 0.25em;
			outline: none;
		}
	}
}