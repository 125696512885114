.shape {
	&--teal {
		.cmp-title {
			position: relative;
			top: -65px;
			display: inline-block;
			color: $color-text-inverted;
			background-color: $color-link;
			border-radius: 0 50px 50px 0;

			&__text {
				font-size: 1em;
				text-transform: uppercase;
				margin-left: -2em;
				padding-right: 0.75em;
			}
		}

		&-short {
			.cmp-title {
				left: -25px;
				padding: 1em 1em 1em 3em;
			}
		}

		&-long {
			.cmp-title {
				left: -9vw;
				padding: 1em 1em 1em 11vw;
			}
		}
	}
}

.text-align {
	&--left {
		.cmp-title__text {
			text-align: left;
		}
	}

	&--center:not([class*=shape--]) {
		.cmp-title__text {
			text-align: center;
		}
	}

	&--right:not([class*=shape--]) {
		.cmp-title__text {
			text-align: right;
		}
	}

	&--horizontal {
		&-center {
			height: 100%;

			.cmp-title {
				height: 100%;
				display: flex;
				align-items: center;
				margin: 0;
			}
		}
	}
}

.text {
	&--dark {
		.cmp-text {
			color: $color-text;
		}
	}

	&--mid-grey {
		.cmp-text {
			color: $color-mid-grey;
		}
	}

	&--light {
		.cmp-text {
			color: $color-text-white;
		}
	}

	&--normal {
		.cmp-text {
			font-size: $font-size;
		}
	}

	&--small {
		.cmp-text {
			font-size: 14px;
		}
	}
}