.container {
	&.full-width {
		& > .cmp-container {
			position: relative;
			
			.aem-Grid {
				position: relative;
			}

			&::before {
				content: "";
				position: absolute;
				top: 0;
				height: 100%;
				width: 100vw;
				background: inherit;
				box-sizing: border-box;
				left: calc((100vw - 100%) / -2);
				right: calc((100vw - 100%) / -2);
			}
		}
	}

	&.container--center {
		.cmp-container {
			display: flex;
		}

		&.container--height-regional-header {
			& > .cmp-container {
				display: block;
			}
		}
	}

	&.container--logo-list {
		& > .cmp-container {
			padding: 0 0 1.5rem;

			.aem-Grid {
				display: flex;
				justify-content: center;
				flex-wrap: wrap;

				@media screen and (min-width: 1000) {
					display: flex;
					justify-content: center;
				}

				&::after,
				&::before {
					content: none;
				}
			}
			
			.image {
				flex: 20%;    
				margin: 16px auto;

				@media screen and (min-width: $mobile) and (max-width: $tablet) {
					flex: 33.3%;
				} 

				@media screen and (min-width: $small-mobile) and (max-width: $mobile) {
					flex: 50%;
				} 
				
				@media screen and (max-width: $small-mobile) {
					flex: 100%;
				}  
			}
		}

		&--2-columns {
			& > .cmp-container {			
				.image {
					flex: 33.3%; 
					margin: 16px auto;
	
					@media screen and (min-width: 1020px) and (max-width: 1500px) {
						flex: 50%;
					}  
					
					@media screen and (min-width: 768px) and (max-width: 1019px) {
						flex: 100%;
					}  

					@media screen and (max-width: 767px) {
						flex: 33.3%;
					}   
				}
			}
		}
	}

	&.container--teaser-list {
		.cmp-container {
			.aem-Grid {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				&::after,
				&::before {
					display: none;
				}

				.teaser {
					width: calc(33.3% - 1em);
					margin: 1em 0.5em;

					@media screen and (min-width: $mobile) and (max-width: 1000px) {
						width: calc(50% - 1em);
					} 

					@media screen and (max-width: $mobile) {
						width: 100%;
					} 

					.cmp-teaser {
						height: 100%;
						
	
						.cmp-teaser__content {
							flex: 1 0 auto;
	
							.cmp-teaser__description {
								flex-grow: 1;
							}
						}
					}
				}

			}
		}
	}

	&--round-border {
		.cmp-container {
			border-radius: 15px;
		}
	}
	
	&--margin {
		&-top {
			&-extra-large {
				.cmp-container:first-of-type {
					margin-top: 56px;
				}
			}

			&-large {
				.cmp-container:first-of-type {
					margin-top: 48px;
				}
			}

			&-medium {
				.cmp-container:first-of-type {
					margin-top: 32px;
				}
			}

			&-small {
				.cmp-container:first-of-type {
					margin-top: 24px;
				}
			}

			&-none {
				.cmp-container:first-of-type {
					margin-top: 0;
				}
			}		
		}

		&-bottom {
			&-extra-large {
				.cmp-container:first-of-type {
					margin-bottom: 56px;
				}
			}

			&-large {
				.cmp-container:first-of-type {
					margin-bottom: 48px;
				}
			}

			&-medium {
				.cmp-container:first-of-type {
					margin-bottom: 32px;
				}
			}

			&-small {
				.cmp-container:first-of-type {
					margin-bottom: 24px;
				}
			}

			&-none {
				.cmp-container:first-of-type {
					margin-bottom: 0;
				}
			}			
		}

		&-left {
			&-extra-large {
				.cmp-container:first-of-type {
					margin-left: 56px;

					@media screen and (max-width: 768px) {
						margin-left: 0;
					}
				}
			}

			&-large {
				.cmp-container:first-of-type {
					margin-left: 48px;

					@media screen and (max-width: 768px) {
						margin-left: 0;
					}
				}
			}

			&-medium {
				.cmp-container:first-of-type {
					margin-left: 32px;

					@media screen and (max-width: 768px) {
						margin-left: 0;
					}
				}
			}

			&-small {
				.cmp-container:first-of-type {
					margin-left: 24px;

					@media screen and (max-width: 768px) {
						margin-left: 0;
					}
				}
			}

			&-none {
				.cmp-container:first-of-type {
					margin-left: 0;
				}
			}		
		}

		&-right {
			&-extra-large {
				.cmp-container:first-of-type {
					margin-right: 56px;

					@media screen and (max-width: 768px) {
						margin-left: 0;
					}
				}
			}

			&-large {
				.cmp-container:first-of-type {
					margin-right: 48px;

					@media screen and (max-width: 768px) {
						margin-left: 0;
					}
				}
			}

			&-medium {
				.cmp-container:first-of-type {
					margin-right: 32px;

					@media screen and (max-width: 768px) {
						margin-left: 0;
					}
				}
			}

			&-small {
				.cmp-container:first-of-type {
					margin-right: 24px;

					@media screen and (max-width: 768px) {
						margin-left: 0;
					}
				}
			}

			&-none {
				.cmp-container:first-of-type {
					margin-right: 0;
				}
			}		
		}
	}
	
	&--padding {
		&-bottom {
			&-none {
				.cmp-container {
					padding-bottom: 0;
				}

				&.container--logo-list {
					.cmp-container {
						padding-bottom: 0;
					}
				}
			}

			&-small {
				.cmp-container {
					padding-bottom: 24px;
				}

				&.container--logo-list {
					.cmp-container {
						padding-bottom: 24px;
					}
				}
			}
	
			&-medium {
				.cmp-container {
					padding-bottom: 32px;
				}

				&.container--logo-list {
					.cmp-container {
						padding-bottom: 32px;
					}
				}
			}
			
			&-large {
				.cmp-container {
					padding-bottom: 48px;
				}

				&.container--logo-list {
					.cmp-container {
						padding-bottom: 48px;
					}
				}
			}
		}

		&-top {
			&-none {
				.cmp-container {
					padding-top: 0;
				}

				&.container--logo-list {
					.cmp-container {
						padding-top: 0;
					}
				}
			}

			&-small {
				.cmp-container {
					padding-top: 24px;
				}

				&.container--logo-list {
					.cmp-container {
						padding-top: 24px;
					}
				}
			}
	
			&-medium {
				.cmp-container {
					padding-top: 32px;
				}

				&.container--logo-list {
					.cmp-container {
						padding-top: 32px;
					}
				}
			}
			
			&-large {
				.cmp-container {
					padding-top: 48px;
				}

				&.container--logo-list {
					.cmp-container {
						padding-top: 48px;
					}
				}
			}
		}
	}

	&--stretch {
		.cmp-container {
			.aem-Grid {
				display: flex;
				justify-content: stretch;

				.teaser {
					display: flex;
					justify-content: stretch;

					.cmp-teaser {
						height: 100%;

						&__image {
    						height: 100%;
						}
					}
				}
			}
		}
	}

	&--height {
		&-100,
		&-regional-header {
			.container--margin-left-large {
				height: 100%;

				.cmp-container {
					height: 100%;
					margin-top: 0;
					margin-bottom: 0;
				}
			}
		}

		&-regional-header {
			& > .cmp-container {
				display: block;

				& > .aem-Grid {
					display: flex;
					flex-wrap: wrap;
					align-items: center;

					.button {
						height: 100%;
						display: flex;
						align-self: flex-start;
						margin-top: 35px;
					}
				}
			}
		}
	}
}

#homepage-container {
	margin-top: 0;
}