
//== Variables

@import 'site/variables';
@import 'site/grid';
@import 'site/animations';

//== Site global

@import 'npm:normalize.css';
@import 'site/base';

//== Core components

// TODO: Check if glob can be used to import with parcel
@import 'src/components/core/text/_text.scss';
@import 'src/components/core/text/_text_link_styles.scss';

@import 'src/components/core/title/_title.scss';
@import 'src/components/core/title/_title_styles.scss';

@import 'src/components/core/image/_image.scss';
@import 'src/components/core/image/_image_styles.scss';
@import 'src/components/core/image/_image_caption.scss';
@import 'src/components/core/image/_image_standard.scss';

@import 'src/components/core/button/_button.scss';
@import 'src/components/core/button/_button_styles.scss';

@import 'src/components/core/separator/_separator.scss';
@import 'src/components/core/separator/_separator_small.scss';
@import 'src/components/core/separator/_separator_standard.scss';

@import 'src/components/core/progressbar/_progressbar.scss';

@import 'src/components/core/teaser/_teaser.scss';
@import 'src/components/core/teaser/_teaser_styles.scss';
@import 'src/components/core/teaser/_teaser_text_top_bottom.scss';
@import 'src/components/core/teaser/_teaser_text_left_right.scss';

@import 'src/components/core/download/_download.scss';
@import 'src/components/core/download/_download_styles.scss';

//== Lists and Navigation components

@import 'src/components/core/list/_list.scss';
@import 'src/components/core/list/_list_styles.scss';

@import 'src/components/core/navigation/_navigation.scss';

@import 'src/components/core/languagenavigation/_languagenavigation.scss';

@import 'src/components/core/breadcrumb/_breadcrumb.scss';
@import 'src/components/core/breadcrumb/_breadcrumb_standard.scss';

@import 'src/components/core/search/_search.scss';

//== Container components

@import 'src/components/core/container/_container.scss';
@import 'src/components/core/container/_container_styles.scss';
@import 'src/components/core/container/_container_content.scss';
@import 'src/components/core/container/_container_main.scss';

@import 'src/components/core/carousel/_carousel.scss';

@import 'src/components/core/tabs/_tabs.scss';
@import 'src/components/core/tabs/_tabs_horizontal.scss';
@import 'src/components/core/tabs/_tabs_vertical.scss';

@import 'src/components/core/accordion/_accordion.scss';

//== Fragments (CF + XF) components

@import 'src/components/core/contentfragment/_contentfragment.scss';
@import 'src/components/core/contentfragmentlist/_contentfragmentlist.scss';

@import 'src/components/core/experiencefragment/_experiencefragment_footer.scss';
@import 'src/components/core/experiencefragment/_experiencefragment_header.scss';
@import 'src/components/core/experiencefragment/_experiencefragment_subheader.scss';

//== Form components

@import 'src/components/core/form/_form.scss';
@import 'src/components/core/form-button/_form-button.scss';
@import 'src/components/core/form-text/_form-text.scss';
@import 'src/components/core/form-options/_form-options.scss';

//== Embed components

@import 'src/components/core/embed/_embed.scss';

@import 'src/components/core/pdfviewer/_pdfviewer.scss';

@import 'src/components/core/socialmediasharing/_social_media_sharing.scss';

//== Custom components
@import 'src/components/core/publication-details/publication-details.scss';
@import 'src/components/custom/navigation/navigation.scss';
@import 'src/components/custom/faceted-search/faceted-search.scss';
@import 'src/components/custom/faceted-search/facet.scss';
@import 'src/components/custom/faceted-search/search-bar.scss';
@import 'src/components/custom/faceted-search/search-result.scss';
