.cmp-faceted-search {
    max-width: 1200px;
    scroll-margin-top: 1.5rem;

    @media screen and (min-width:$tablet) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__search-bar {
        @media screen and (min-width:$tablet) {
            width: 100%;
            flex: 1 0 auto;
        }

        margin: 0 0 1em 0;
    }

    &__facets {
        @media screen and (min-width:$tablet) {
            flex-basis: 250px;
            flex-grow: 1;
            margin-top: 1em;
            margin-right: 1.5em;
        }

        >.cmp-container {
            margin: 0;
            display: block;
        }


    }

    &__results {
        margin-top: 1em;

        @media screen and (min-width:$tablet) {
            flex-basis: 0;
            flex-grow: 999;
        }
    }
}

.cmp-faceted-search__facets__title {
    font-size: 1.25rem;
}

.cmp-faceted-search__facets__trigger {
    appearance: none;
    -webkit-appearance: none;
    background-color: $color-accent;
    color: white;
    text-decoration: none;
    padding: 8px 24px;
    border: 2px solid $color-accent;
    border-radius: 10rem;
    cursor: pointer;
    transition: background-color 0.5s, border-color .5s;
    display: inline-flex;
    flex-direction: row-reverse;
    align-items: center;

    &-icon {
        width: 18px;
        height: 18px;
        fill: #fff;
        display: inline-block;
        margin-left: 8px;
    }

    &-txt {
        font-size: 16px;


    }

    &:hover {
        background-color: $color-accent-dark;
        border-color: $color-accent-dark;
    }

    &:focus {
        background-color: $color-accent-dark;
        border-color: $color-accent-dark;
        outline-offset: 1px;
        outline: $color-accent-lighter solid 2px;
    }

    @media screen and (min-width:$tablet) {
        display: none;
    }
}

.cmp-faceted-search__facets__close {
    appearance: none;
    -webkit-appearance: none;
    background-color: $color-accent;
    color: white;
    text-decoration: none;
    padding: 8px 24px;
    border: 2px solid $color-accent;
    border-radius: 10rem;
    cursor: pointer;
    transition: background-color 0.5s, border-color .5s;
    font-size: 16px;

    @media screen and (min-width:$tablet) {
        display: none;
    }

    &:hover {
        background-color: $color-accent-dark;
        border-color: $color-accent-dark;
    }

    &:focus {
        background-color: $color-accent-dark;
        border-color: $color-accent-dark;
        outline-offset: 1px;
        outline: $color-accent-lighter solid 2px;
    }
}

.cmp-faceted-search__facets__clear {
    appearance: none;
    -webkit-appearance: none;
    background: transparent;
    color: $color-link;
    cursor: pointer;
    color: $color-text;
    border: 0;
    padding: 8px 0;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    text-decoration: underline;

    &:hover {
        color: $color-link-darkest;

    }

    &:focus {
        color: $color-link-darkest;
        outline-offset: 1px;
        outline: $color-accent-lighter solid 2px;
    }

    &[disabled] {
        display: none;
    }
}


.js-facets-shown {
    overflow: hidden;
}

.cmp-faceted-search__facets__modal {
    .js-facets-shown & {
        display: flex;
    }

    @media screen and (max-width:$tablet) {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #fff;
        overflow-y: auto;
        z-index: 9000;
        display: flex;
        flex-direction: column;
        display: none;
    }

    &-bd {
        @media screen and (max-width:$tablet) {
            background-color: #fff;
            flex: 1 0 auto;
            overflow-y: auto;
            padding-bottom: 100px;
            position: relative;
            height: 1px;
            padding: 1rem;
        }

        >.cmp-container {
            margin: 0;
            display: block;
        }

        .cmp-faceted-search__facets__clear {
            display: none;

            @media screen and (min-width:$tablet) {
                display: inline-block;
                margin: 0;
            }
        }
    }

    &-footer {
        @media screen and (max-width:$tablet) {
            background-color: #fff;
            flex: 0 0 auto;
            padding: 1rem;
            border-top: 2px solid $color-shade-1;
        }

        .cmp-faceted-search__facets__clear {
            margin-left: 1em;

            @media screen and (min-width:$tablet) {
                display: none;
            }
        }
    }
}