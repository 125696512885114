.cmp-image {
	position: relative;

	.cmp-image__alt-text {
		position: absolute;
		bottom: 0;
		width: 122px;
		text-align: center;
		background-color: rgba(0, 0, 0, 0.5);
		color: white;
		border-bottom-left-radius: 15px;
		border-bottom-right-radius: 15px;
		opacity: 0;
		transition: all 300ms ease;

		&:hover {
			opacity: 1; 
			transition-delay: 0s;
		}
	}

	&__image {
		display: block;
		position: relative;
	}

	&__link {
		position: relative;

		&:hover {
			.cmp-image__alt-text {
				opacity: 1; 
				transition-delay: 0s;
			}
		}
	}

	&:hover {
		.cmp-image__alt-text {
			opacity: 1; 
			transition-delay: 0s;
		}
	}
}