.contentfragment {

	.cmp-contentfragment__element {
		&--title,
		&--description {
			display: none;
		}
	}

	&--table {
		&-11 {
			.cmp-contentfragment {
				&__element {
					flex: 1 0 8.33%;

					&:nth-child(11n+0),
					&:last-child {
						@media screen and (min-width: 778px) {
							border-top-right-radius: 5px;
							border-bottom-right-radius: 5px;
							border-right: 2px solid $color-link-dark;
						}
					}

					&:nth-child(11n+1) {
						@media screen and (min-width: 778px) {
							border-top-left-radius: 5px;
							border-bottom-left-radius: 5px;
						}
					}
				}
			}
		}

		&-10 {
			.cmp-contentfragment {
				&__element {
					flex: 1 0 9.09%;

					&:nth-child(10n+0),
					&:last-child {
						@media screen and (min-width: 778px) {
							border-top-right-radius: 5px;
							border-bottom-right-radius: 5px;
							border-right: 2px solid $color-link-dark;
						}
					}

					&:nth-child(10n+1) {
						@media screen and (min-width: 778px) {
							border-top-left-radius: 5px;
							border-bottom-left-radius: 5px;
						}
					}
				}
			}
		}

		&-8 {
			.cmp-contentfragment {
				&__element {
					flex: 1 0 11.11%;

					&:nth-child(8n+0),
					&:last-child {
						@media screen and (min-width: 778px) {
							border-top-right-radius: 5px;
							border-bottom-right-radius: 5px;
							border-right: 2px solid $color-link-dark;
						}
					}

					&:nth-child(8n+1) {
						@media screen and (min-width: 778px) {
							border-top-left-radius: 5px;
							border-bottom-left-radius: 5px;
						}
					}
				}
			}
		}

		&-11,
		&-10,
		&-8 {
			.cmp-contentfragment {
				&__elements {
					display: flex;
					flex-wrap: wrap;
				}

				&__element {
					display: flex;
					align-items: center;
					flex-direction: column;
					border: 2px solid $color-link-dark;
					border-right: none;
					margin: 8px 0;


					@media screen and (max-width: 777px) {
						flex: 1 0 21%;
					}

					&:nth-child(4n+0),
					&:last-child {
						@media screen and (max-width: 777px) {
							border-top-right-radius: 5px;
							border-bottom-right-radius: 5px;
							border-right: 2px solid $color-link-dark;
						}
					}

					&:nth-child(4n+1) {
						@media screen and (max-width: 777px) {
							border-top-left-radius: 5px;
							border-bottom-left-radius: 5px;
						}
					}

					&-title {
						color: $color-text-white;
						background-color: $color-link-dark;
						text-align: center;
						width: 100%;
						display: flex;
						justify-content: center;
						align-items: center;				   
					}
			
					&-value {
						margin: 0;    
						padding: 0 8px;
					}
				}
			}
		}
		
	}

	&--table-hide-title {
		.cmp-contentfragment {
			&__title {
				display: none;
			}

			&__elements {
				margin-top: 0;
			}
		}
	}

	&--table-hide-description {
		.cmp-contentfragment {
			&__description {
				display: none;
			}
		}
	}

	&--strech-title {
		.cmp-contentfragment {
			&__element {
				&-title {
					flex: 1;
					@media screen and (max-width: 777px) {
						height: 110px;
						flex: initial;
					}
				}
			}
		}
	}

	&--big-table {
		.cmp-contentfragmentlist {
			.cmp-contentfragment {
				.cmp-contentfragment__element {
					margin: 0;
					border-radius: 0;
					border-right: none;

					&:nth-child(5n+0),
					&:last-child {
						border-right: 2px solid $color-link-dark;
					}

					@media screen and (max-width: 777px) {
						flex: 1 0 15%;
					}

					&-title,
					&-value {
						@media screen and (max-width: 777px) {
							font-size: 16px;
							word-break: break-word;
							hyphens: auto;
						}
					}

					&-title {
						@media screen and (max-width: 777px) {
							height: 110px;
							flex: initial;
						}
					}

					&:first-child:nth-last-child(5),
					&:first-child:nth-last-child(5) ~ .cmp-contentfragment__element {
						@media screen and (max-width: 777px) {
							max-width: 64px;
						}
					}
				}

				&:first-child {
					.cmp-contentfragment__element {
						margin-top: 8px;

						&:nth-child(5n+0),
						&:last-child {
							border-top-right-radius: 5px;
						}

						&:nth-child(5n+1) {
							border-top-left-radius: 5px;
						}
					}

				}

				&:last-child {
					.cmp-contentfragment__element {
						margin-bottom: 8px;

						&:nth-child(5n+0),
						&:last-child {
							border-bottom-right-radius: 5px;
						}

						&:nth-child(5n+1) {
							border-bottom-left-radius: 5px;
						}
					}

				}

				&:not(:first-child) {
					.cmp-contentfragment__element {
						&-title {
							display: none;
						}
					}
					.cmp-contentfragment__element-title {
						display: none;
					}
				}

				&:not(:last-child) {
					.cmp-contentfragment__elements {
						margin-bottom: 0;

						.cmp-contentfragment__element {
							border-bottom: none;
						}
					}
				}
			}
		}
	}
}