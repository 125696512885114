#footer-content {
	background-color:#eceff1;

	&>.aem-Grid {
		width: 85%;
		margin: 0 auto;
		padding: 2.5em 0;

		&>.container {
			padding: 0 16px;

			@media screen and (max-width:$tablet) {
				.cmp-container {
					margin-top: 24px;
				}
			}

			.cmp-title {
				margin-top: 0;

				&__text {
					@media screen and (max-width:$tablet) {
						text-align: center;
						font-size: 18px;
					}
				}
			}

			.cmp-text {
				@media screen and (max-width:$tablet) {
					text-align: center;
				}
			}

			&:first-child {
				@media screen and (max-width:$tablet) {
					.cmp-container {
						margin-top: 0px;
					}
				}
			}

			&:last-child {
				padding: 0;

				.cmp-container {
					margin-left: 56px;
					@media screen and (max-width:$tablet) {
						margin-top: 48px;
						margin-left: 0;
					}
				}
			}
		}

		.list {
			.cmp-list {
				padding-left: 0;

				@media screen and (max-width:$tablet) {
					margin-top: 0;
				}

				&__item {
					@media screen and (max-width:$tablet) {
						text-align: center;
					}

					&-link {
						margin: 0;
					}
				}
			}
		}

		#social-links {
			&>.aem-Grid {
				display: flex;
				flex-direction: column;
				align-items: center;
			}

			#socials {
				margin-top: 0;
				margin-bottom: 2em;

				&>.aem-Grid {
					display: flex;
					flex-direction: row;

					.image {
						width: 20%;

						.cmp-image {
							margin-top: 0;

							.cmp-image__image {
								max-width: 1.5em;

								@media screen and (max-width:$tablet) {
									margin: 0 auto;
								}
							}

							&__title {
								display: none;
							}
						}
					}
				}
			}

			.button {
				margin-left: 0;
				display: flex;
				justify-content: center;
			}
		}
	}
}

#footer-copyright {
	background-color: #267589;
	margin-top: 0;
	padding: 16px 0;
	justify-content: center;
	flex-direction: column;

	.text,
	.title {
		color: $color-background;
		text-align: center;
	}

	.cmp-text {
		color: #ffffff;

		a {
			font-size: 16px;
			color: $color-text-white;
			text-decoration-color: $color-text-white;

			&:hover {
				color: $color-text-white;
				text-decoration-color: $color-accent;
			}
		}
	}
}

#main-footer2 {
	.container {
		float: none;

		.container {
			float: left;
		}
	}
}