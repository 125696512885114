.header {
	box-shadow: 0 2px 4px #00000026;
	position: relative;
	z-index: 100;
	background-color: $color-link;
}

//== XF Header style, used on page template

#main-header {
	padding: 1.25rem 0 0 0 ;
	width: 80%;
	margin: 0 auto;

	@media screen and (max-width:$tablet-large) {
		position: relative;
		width: 100vw;
		padding: 0;

		& > .aem-Grid {
			width: 80%;
			margin: 0 auto;
			padding: 1.25rem 0 0;
		}
	}

	#fatf-logo {
		max-width: 80px;
		margin-top: -26px;
	}

	//=================== Responsive menu ===================

	#menu-toggle {
		display: none;
		border: none;
		margin: 0;
		padding: 0;
		text-indent: -9999999em;
		background-color: transparent;
		width: 3rem;
		height: 3rem;
		border-radius: 0;

		&:after {
			content: '\2715';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			text-indent: 0;
			font-size: 2em;
			line-height: 1em;
			color: $color-accent;
		}

		@media screen and (max-width:$tablet-large) {
			display: block;
			position: absolute;
			top: 1em;
			right: 2em;
		}
	}

	//toggle hamburger menu
	&.menu-hidden {
		@media screen and (max-width:$tablet-large) {
			#menu-toggle:after {
				color: $color-black;
				content: "\2630";
			}

			.navigation,
			#header-search-lang {
				display: none;
			}
		}
	}

	//=================== Grid layout ===================

	&.cmp-container {
		& >  .aem-Grid {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;

			@media screen and (max-width:$tablet-large) {
				flex-direction: column;
				justify-content: flex-end;
				align-items: center;
			}

			& .image {
				flex-grow: 1;
				margin-left: 0;

				@media screen {
					align-self: flex-start;
					width: 15%;
					margin-bottom: 1em;
				}
			}

			& > .container {
				width: 50%;

				@media screen and (max-width:$tablet-large) {
					width: 80%;
					margin: 0 auto;
					order: 3;
				}
			}

			& .navigation {
				@media screen and (max-width:$tablet-large) {
					.cmp-navigation {
						height: initial;
						margin-right: 0;
					}
				}
			}
		}
	}

	#header-search-lang {
		margin-top: 0;
		justify-content: flex-end;

		& .aem-Grid {
			display: flex;
			justify-content: flex-end;

			@media screen and (max-width:$tablet-large) {
				flex-direction: column;
			}

			.search {
				width: 50%;
			}

			.languagenavigation {
				width: 30%;
			}

			.search,
			.languagenavigation {
				@media screen and (max-width:$tablet-large) {
					width: 100%;
					margin-bottom: 1em;
				}
			}

			.image {
				display: flex;
				justify-content: flex-end;
				margin-right: 2rem;
				margin-bottom: 0;
				align-self: center;

				@media screen and (max-width:$tablet-large) {
					display: block;
    				justify-content: center;
					align-self: center;
					margin-right: 0;
					margin-bottom: 1em;
				}

				.cmp-image__image {
					width: 32px;
				}
			}
		}
	}

	//=================== Navigation ===================

	.cmp-navigation {
		height: 2.5em;

		@media screen and (max-width:$tablet-large) {
			margin-left: 0;
		}
	}

	.cmp-navigation__group {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin: 0;
		padding: 0;
		list-style: none;
		justify-content: end;

		@media screen and (max-width:$tablet-large) {
			flex-direction: column;
			justify-content: flex-end;
			align-items: center;
			margin-top: 0;
		}
	}

	.cmp-navigation__item--level-0 {
		position: relative;
		display: inline-flex;
		font-size: 20px;

		&>a {
			padding: 0.5625rem 1rem 0.6875rem 1rem;

			@media screen and (max-width:$tablet-large) {
				padding-left: 0;
			}
		}

		&:hover {
			&>.cmp-navigation__item-link {
				@media screen and (min-width:$tablet-large) {
					color: $color-accent-lighter;
					background-size: 0 0.1em, 80% 0.15em;
				}
			}
		}

		&>.cmp-navigation__item-link {
			background:
				linear-gradient(to right, transparent),
				linear-gradient(to right, $color-accent-lighter);
			background-size: 100% 0.1em, 0 0.15em;
			background-position: 100% 100%, 12px 100%;
			background-repeat: no-repeat;
			transition: 0.7s;
		}

		&:focus {
			border: 0.125em solid $color-accent-lighter;
			border-radius: 0.25em;
			outline: none;

			.cmp-navigation__item-link {
				color: $color-text;
			}
		}

		&>.cmp-navigation__group {
			position: absolute;
			top: 3.4em;
			z-index: 5;
			display: grid;
			width: max-content;
			padding: 0.25rem 0;
			overflow: hidden;
			background: $color-background;
			border: 0.0625rem solid $color-shade-2;
			border-radius: 0.25em;
			box-shadow: 0 0.25em 0.5em 0.2em $color-shadow;
			visibility: collapse;
			transition-delay: 0.5s;
		}
	}

	.cmp-navigation__item--level-1 {
		.cmp-navigation__item-link {
			color: $color-text;
		}
	}

	.cmp-navigation__item--level-0:nth-child(2) {

		.cmp-navigation__item-link {
			padding-right: 8px;
		}

		&:after {
			display: inline-block;
			width: 24px;
			height: 24px;
			margin: 0.5em 0.5em 0.5em 0;
			background-color: $color-shade-2;
			content: '';
			pointer-events: none;
			mask: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48ZGVmcz48c3R5bGU+LmEsLmJ7ZmlsbDojNjA3ZDhiO30uYXtvcGFjaXR5OjA7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC01NjUgLTgwKSI+PHJlY3QgY2xhc3M9ImEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNTY1IDgwKSIvPjxwYXRoIGNsYXNzPSJiIiBkPSJNLjI5MywxMy4wMjFhMSwxLDAsMCwxLDAtMS40MTRsNC45NS00Ljk1TC4yOTMsMS43MDdBMSwxLDAsMCwxLDEuNzA3LjI5M0w3LjM2NCw1Ljk1YTEsMSwwLDAsMSwwLDEuNDE1TDEuNzA3LDEzLjAyMWExLDEsMCwwLDEtMS40MTUsMFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDU4My44MjkgODguMTcxKSByb3RhdGUoOTApIi8+PC9nPjwvc3ZnPg==');
			mask-size: cover;
			transition: 0.5s;
		}

		&:hover {
			&>.cmp-navigation__group {
				visibility: visible;
			}

			&:after {
				transform: rotate(180deg);
				background-color: $color-accent-lighter;
			}
		}

		& .cmp-navigation__group .cmp-navigation__item {
			padding: 0.5625em 2em 0.6875em 1em;

			&:hover {
				background-color: $color-shade-1;

				& a {
					color: $color-text;
				}
			}
		}
	}

	.cmp-navigation__item-link {
		display: block;
		color: $color-shade-4;
		text-decoration: none;
	}

	.cmp-navigation__item--active {
		a {
			color: $color-accent;
		}
	}

	//=================== Language Navigation ===================

	.cmp-languagenavigation {
		position: relative;
		height: 2.5em;
		margin: 0 0.5em;
		background-color: $color-shade-1;
		border: 0.125rem solid $color-shade-1;
		border-radius: 0.25em;

		@media screen and (max-width:$tablet-large) {
			margin: 0;
		}

		& span {
			display: inline-block;
			color: $color-shade-3;
			vertical-align: middle;
			padding: 0.5em 0 0 1em;

			&:before {
				margin: 0.6875em 0.5em 0.6875em 0.75em;
				vertical-align: -70%;
			}
		}

		&:after {
			display: inline-block;
			float: right;
			width: 24px;
			height: 24px;
			margin: 0.5em;
			vertical-align: -40%;
			background-color: $color-shade-3;
			content: '';
			mask: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48ZGVmcz48c3R5bGU+LmEsLmJ7ZmlsbDojNjA3ZDhiO30uYXtvcGFjaXR5OjA7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC01NjUgLTgwKSI+PHJlY3QgY2xhc3M9ImEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNTY1IDgwKSIvPjxwYXRoIGNsYXNzPSJiIiBkPSJNLjI5MywxMy4wMjFhMSwxLDAsMCwxLDAtMS40MTRsNC45NS00Ljk1TC4yOTMsMS43MDdBMSwxLDAsMCwxLDEuNzA3LjI5M0w3LjM2NCw1Ljk1YTEsMSwwLDAsMSwwLDEuNDE1TDEuNzA3LDEzLjAyMWExLDEsMCwwLDEtMS40MTUsMFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDU4My44MjkgODguMTcxKSByb3RhdGUoOTApIi8+PC9nPjwvc3ZnPg==');
			mask-size: cover;
		}

		&:hover {
			border-color: $color-link-hover;
			background-color: #fff;

			&:after {
				background-color: $color-shade-3;
				mask: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48ZGVmcz48c3R5bGU+LmEsLmJ7ZmlsbDojNjA3ZDhiO30uYXtvcGFjaXR5OjA7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC01MjUgLTgwKSI+PHJlY3QgY2xhc3M9ImEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNTI1IDgwKSIvPjxwYXRoIGNsYXNzPSJiIiBkPSJNLjI5MywxMy4wMjFhMSwxLDAsMCwxLDAtMS40MTVsNC45NS00Ljk1TC4yOTMsMS43MDdBMSwxLDAsMCwxLDEuNzA3LjI5M0w3LjM2NCw1Ljk1YTEsMSwwLDAsMSwwLDEuNDE0TDEuNzA3LDEzLjAyMWExLDEsMCwwLDEtMS40MTUsMFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDUzMC41MTUgOTUuODI4KSByb3RhdGUoLTkwKSIvPjwvZz48L3N2Zz4=');
			}

			>.cmp-languagenavigation__group {
				visibility: visible;
				transition-delay: 0s;
			}
		}
	}

	.cmp-languagenavigation__group {
		position: absolute;
		top: 2.8em;
		z-index: 5;
		display: grid;
		width: 100%;
		margin: 0;
		padding: 0.25rem 0;
		overflow: hidden;
		list-style: none;
		background: $color-background;
		border: 0.0625rem solid $color-shade-2;
		border-radius: 0.25em;
		box-shadow: 0 0.25em 0.5em 0.2em $color-shadow;
		visibility: hidden;
		transition-delay: 0.5s;
	}

	.cmp-languagenavigation__item {
		height: 2.5rem;
		padding: .5em 0 0 1em;

		&:not(.cmp-languagenavigation__item--active) {
			&:hover {
				background-color: $color-shade-1;
			}
		}
	}

	.cmp-languagenavigation__item-link {
		margin: 0.5625em 0 0.6875em 0;
		color: $color-text;
		font-weight: normal;
		font-size: 1em;
		text-decoration: none;
	}

	.cmp-languagenavigation__item--active {
		padding: .5em 0 0 1em;
		background-color: $color-link;

		&>.cmp-languagenavigation__item-link {
			color: $color-black;
			font-weight: normal;
		}

		&>div::after {
			display: inline-block;
			float: right;
			width: 24px;
			height: 24px;
			margin: 0.5em 0.5em 0.5em auto;
			vertical-align: middle;
			background-color: $color-black;
			content: '';
			mask: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48ZGVmcz48c3R5bGU+LmEsLmJ7ZmlsbDojMjY4ZTZjO30uYXtvcGFjaXR5OjA7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MDMgLTgwKSI+PHJlY3QgY2xhc3M9ImEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNzAzIDgwKSIvPjxwYXRoIGNsYXNzPSJiIiBkPSJNNC45MzIsMjY5LjlhMSwxLDAsMCwxLS44NjQtLjQ2N0wuNzA3LDI2Ni4wNzJhMSwxLDAsMSwxLDEuNDE0LTEuNDE1bDIuODExLDIuODExLDcuNzYxLTcuNzYxYTEsMSwwLDEsMSwxLjQxNCwxLjQxNUw1LjgsMjY5LjQzMmExLDEsMCwwLDEtLjg0Ny40NjhaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3MDcuNTg2IC0xNzIuNjU3KSIvPjwvZz48L3N2Zz4=');
			mask-size: cover;
		}
	}

	//=================== Search ===================

	.cmp-search__field {
		display: flex;
		height: 2.5em;
	}

	.cmp-search__input {
		padding: 0 0.5em 0 2.375em;
		color: $color-shade-3;
		font-size: 1em;
		background-color: $color-shade-1;
		border: 0.125rem solid $color-shade-1;
		border-radius: 0.25em;

		&::placeholder {
			color: $color-shade-3;
		}

		&:hover {
			background-color: $color-background;
			border-color: $color-link-hover;
		}

		&:focus {
			border-color: $color-link-hover;
			outline: none;
		}

		&:active {
			background-color: $color-background;
			border-color: $color-link-hover;
			outline: none;
		}
	}

	.cmp-search__icon {
		top: 0.125em;
		left: 0.125em;
		width: 24px;
		height: 24px;
		margin: 0.5em 0.5em 0.5em 0.375em;
		background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48ZGVmcz48c3R5bGU+LmEsLmJ7ZmlsbDojNjA3ZDhiO30uYXtvcGFjaXR5OjA7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00MDUgLTgwKSI+PHJlY3QgY2xhc3M9ImEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDA1IDgwKSIvPjxwYXRoIGNsYXNzPSJiIiBkPSJNNDIuMDIxLTMuNTY1LDM4LjY4Ni02LjlhNS40NzMsNS40NzMsMCwwLDEtMy4xMTYuOTY5LDUuNSw1LjUsMCwwLDEtNS41LTUuNSw1LjUwNyw1LjUwNywwLDAsMSw1LjUtNS41LDUuNTA3LDUuNTA3LDAsMCwxLDUuNSw1LjUsNS40NjQsNS40NjQsMCwwLDEtLjk3LDMuMTE0bDMuMzMzLDMuMzM0YTEsMSwwLDAsMSwwLDEuNDE0LDEsMSwwLDAsMS0uNzA3LjI5M0ExLDEsMCwwLDEsNDIuMDIxLTMuNTY1Wk0zMS45MDUtMTEuNDI3YTMuNjcsMy42NywwLDAsMCwzLjY2NiwzLjY2NiwzLjY3MSwzLjY3MSwwLDAsMCwzLjY2OC0zLjY2NkEzLjY3MywzLjY3MywwLDAsMCwzNS41NzEtMTUuMSwzLjY3MSwzLjY3MSwwLDAsMCwzMS45MDUtMTEuNDI3WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzc5LjkyOSAxMDEuOTI5KSIvPjwvZz48L3N2Zz4=');
	}

	.cmp-search__clear-icon {
		display: none;
	}

	.cmp-search__loading-indicator {
		top: 0.125em;
		left: 0.125em;
		width: 18px;
		height: 18px;
		margin: 0.5em 0.5em 0.5em 0.375em;
	}

	.cmp-search__results {
		position: absolute;
		top: 3em;
		width: 100%;
		max-height: 7.375em;
		padding: 0.25em 0;
		border: 0.0625rem solid $color-shade-2;
		border-radius: 0.25em;
		box-shadow: 0 0.25em 0.5em 0.2em $color-shadow;

		&:empty {
			display: none !important; //important is needed becaause OTB js sets display style inline on the element
		}
	}

	.cmp-search__item {
		min-height: 1.25rem;
		padding: 0.5625em 1em 0.6875em 1em;
		color: $color-text;
		text-decoration: none;

		&:hover {
			background-color: $color-shade-1;
		}
	}

	.cmp-search__item-mark {
		background: none;
	}

	//== XF Content Fix

	.xf-content-height {
		min-height: auto;
	}
}

// FATF Header logo position
.page>.container.responsivegrid>.cmp-container {
	margin-top: 0;
	overflow-x: hidden;
}

.js-menu-open {
	&.page {
		@media screen and (max-width:$tablet-large) {
			overflow: hidden;
		}

		& >.container.responsivegrid {
			@media screen and (max-width:$tablet-large) {
				overflow: hidden;
			}
		}

		#main-header {
			@media screen and (max-width:$tablet-large) {
				overflow-y: scroll;
				overflow-x: hidden;
				height: 100vh;
				
				& > .aem-Grid {
					justify-content: flex-start;
					height: fit-content;
				}
			}
		}
	}
}