
//== Defaults

html, body {
  margin: 0;
  height: 100%;
  color: $color-text;
  font-size: $font-size;
  font-family: $font-family;
  line-height: 1.5;
  background: $color-background;
}

body {
	& > .root {
		overflow: hidden;
	}
}

a {
  color: $color-link-dark;
}

p {
  margin-block: 0.75rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block: 0;

  &.text-align {
    &--left {
      text-align: left;
    }

    &--center {
      text-align: center;
    }

    &--left {
      text-align: right;
    }
  } 
}

h1 {
  line-height: 1.3;
  font-size: 44px; //desktop 
  @media screen and (max-width: 650px) { //tablet and smaller
    font-size: 30px;
    
  }
}

h2{
  font-size: 36px; //desktop 
  @media screen and (max-width: 650px) { //tablet and smaller
    font-size: 28px;
    
  }
}

h3{
  font-size: 28px; //desktop 
  @media screen and (max-width: 650px) { //tablet and smaller
    font-size: 22px;
    
  }
}

h4{
  font-size: 22px; //desktop 
  @media screen and (max-width: 650px) { //tablet and smaller
    font-size: 20px;
    
  }
}

h5{
  font-size: 20px; //desktop 
  @media screen and (max-width: 650px) { //tablet and smaller
    font-size: 18px;
    
  }
}

h6{
  font-size: 18px; //desktop 
  @media screen and (max-width: 650px) { //tablet and smaller
    font-size: 16px;
    
  }
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
}

//== Grid

$max_col: 12;

// Default breakpoint
.aem-Grid {
  @include generate-grid(default, $max_col);
}

// Phone breakpoint
@media (max-width: 768px) {
  .aem-Grid {
    @include generate-grid(phone, $max_col);
  }
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;  /* 2 */
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; 
}
