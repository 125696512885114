.download {
	&--highlight { 
		.cmp-download {
			background-color: $color-link;

			&__title {
				&-link {
					color: $color-text-white;
				}
			}
		
			&__description {
				color: $color-text-white;
			}
		
			&__properties {
				color: $color-text-white;
			}

			&__action {
				background-color: $color-accent;
				border-color: $color-accent;

				&:hover {
					background-color: $color-accent-dark;
					border-color: $color-accent-dark;
				}

				&:active {
					background-color: $color-accent-darker;
					border-color: $color-accent-darker;
				}

				&-text {
					color: $color-text-inverted;
				
					&:before {
						background-color: $color-text-inverted;
					}
				}
			}
		}
	}

	&--small {
		.cmp-download {
			word-break: break-word;
			text-overflow: ellipsis;

			&__title {
				&-link {
					-webkit-line-clamp: 3;
				}
			}

			&__properties {
				width: 100%;
				flex-direction: column-reverse;
				
				@media screen and (min-width: 768px) {
					position: relative;
					left: 0;
				}
			}

			&__property--size {
				margin-bottom: 8px;
			}

			&__action {
				display: flex;
				padding: 8px;
				flex-wrap: wrap;
				justify-content: center;

				&-text {
					-webkit-line-clamp: 1;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					white-space: break-spaces;
				}
			}
		}
	}
}