.subheader {
    background-color: $color-shade-1;

    .xf-content-height {
        min-height: 46px;
        margin: 0 -12px;
    }

    #subheader {
        width: 80%;
        margin: 0 auto;
        &.cmp-container {
            .aem-Grid {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: baseline;
                margin-top: 1em;
                @media (max-width:$tablet-large) {
                    margin: 1.5em 0;
                    flex-direction: column;
                    justify-content: center;
                }

                .text {
                    flex: 1 0 15%;
                }

                .list {
                    .cmp-list {
						display: flex;
						flex: 1 0 33.33%;
                        padding-left: 0;
                        margin-top: 0;
                        flex: 1;

						@media screen and (max-width: $tablet-large) {
							flex: 1 0 100%;
							flex-direction: column;
							align-items: center;
						}

						&__item {
							flex: 1;

							&-link {
								margin-right: 0;
							}
						}
                    }
                }

                .button {
                    flex: 1 0 15%;
                    text-align: right;
                    @media (max-width:$tablet-large) {
                        text-align: center;
                    }
                }
            }
        }
    }
}