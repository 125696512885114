
.dropdown {
	.navigation {
		display: flex;
		justify-content: center;
		position: relative;
	
		@media screen and (max-width:$tablet-large) {
			flex-direction: column;
			margin-bottom: 48px;
			padding-top: 24px;

			&::before {
				content: "";
				width: 100vw;
				background-color: $color-link-light;
				height: 1px;
				left: 50%;
				right: 50%;
				margin-left: -50vw;
				margin-right: -50vw;
				position: absolute;
				top: 0;
			}
		}
	
		&_trigger {
			font-size: 20px;
			color: $color-black;
			background: transparent;
			border: none;
			border-bottom: 2px solid transparent;
			padding: 8px 24px 1.25rem 24px;
			margin: 0 8px;
			transition: 0.5s;
			display: flex;
			align-items: center;
			cursor: pointer;
	
			&[aria-expanded="false"] {
				+ .navigation_dropdown-menu {
					display: none;
				}
			}

			&[aria-expanded="true"] {
				+ .navigation_dropdown-menu {
					&[data-closing] {
						display: block;
						pointer-events: none;
						animation: fadeout 200ms forwards;
					}
				}
			}
	
			&[aria-expanded="true"] {	
				border-color: $color-accent;

				.icon--navigation {
					transform: rotate(-90deg);
				}
			}
	
			&:hover {
				border-color: $color-accent;
			}

			@media screen and (max-width:$tablet-large) {
				text-align: left;
				padding: 8px 0;
				margin: 0;
				border-color: $color-black;
			}

			.icon--navigation {
				display: block;
				color: $color-black;
				width: 16px;
    			height: 16px;
				transition: 0.5s;
				margin-left: 16px;
				transform: rotate(90deg);

				@media screen and (max-width:$tablet-large) {
					transform: rotate(0deg);
				}
			}
		}
	
		&_dropdown-menu {
			background-color: white;
			animation: fadein 200ms forwards;
	
			@media screen and (min-width:$tablet-large) {
				width: 100vw;
				max-width: 100vw;
				position: absolute;
				top: 63px;
				left: 50%;
				right: 50%;
				margin-left: -50vw;
				margin-right: -50vw;
				box-shadow: 0 3px 4px #00000026;
				border-top: 1px solid $color-link-a25;
				transition: 0.3s;
				display: block;
			}
	
			#subheader {
				width: 80%;
				margin: 0 auto;
				padding: 32px 0;

				@media screen and (max-width:$tablet-large) {
					width: 100%;
					padding: 0;
					background-color: $color-link;
					color: $color-black;
				}
			
				& > .aem-Grid {
					display: flex;
					flex-direction: row;
	
					@media screen and (max-width:$tablet-large) {
						flex-direction: column;
						gap: 24px;
					}
					
					& > .aem-GridColumn {
						max-width: calc(25% - 32px);
						margin: 0 16px;
	
						@media screen and (max-width:$tablet-large) {
							max-width: 100%;
							margin: 12px 0;
	
							&:first-child {
								margin-top: 0;
							}
	
							&:last-child {
								margin-bottom: 0;
							}
						}
					
						.cmp-list {
							padding: 0;
							margin: 0;
	
							&__item {
								padding: 4px 0;
	
								&-link {
									font-size: 16px;
									color: $color-mid-grey;

									@media screen and (max-width:$tablet-large) {
										color: $color-black;
									}
	
									&:after {
										display: none;
									}
	
									&:hover {    
										color: $color-link-hover;

										@media screen and (max-width:$tablet-large) {
											color: $color-black;
										}

										.cmp-list__item-title {
											text-decoration-color: $color-link-hover;

											@media screen and (max-width:$tablet-large) {
												text-decoration-color: $color-accent;
											}
										}
									}
								}
	
								&:first-child {
									padding-top: 0;
								}
	
								&:last-child {
									padding-bottom: 0;
								}
							}
						}
	
						.cmp-container {
							margin: 0;
						}
	
						.cmp-text {
							margin-bottom: 16px;
							border-bottom: 1px solid $color-link;

							@media screen and (max-width:$tablet-large) {
								text-decoration: underline;
								color: $color-black;
							}
	
							p {
								margin-bottom: 4px;
								margin-top: 0;
								font-weight: bold;
							}
						}
	
						&.container--wide {
							max-width: calc(50% - 32px);
	
							@media screen and (max-width:$tablet-large) {
								max-width: 100%;
								margin: 0;
							}
	
							.cmp-list {
								column-count: 3;
								column-gap: 24px;
	
								@media screen and (min-width:$mobile) and (max-width: $tablet-large){
									column-count: 2;
								}

								@media screen and (max-width:$mobile) {
									column-count: 1;
								}
							}
						}
	
						&.container--small {
							max-width: calc(15% - 32px);
	
							@media screen and (max-width:$tablet-large) {
								max-width: 100%;
								margin: 0;
							}
						}
	
						&.container--no-title {
							.cmp-container {
								padding-top: 0.75rem;

								@media screen and (max-width:$tablet-large) {
									padding-top: 0;
								}
							}
						}
	
						&.separator {
							width: 1px;
						
							.cmp-separator {
								height: 100%;
								width: 100%;
	
								&__horizontal-rule {
									height: 100%;
									margin: 0;
									border-radius: 50px;
								}
							}
	
							@media screen and (max-width:$tablet-large) {
								display: none;
							}
						}

						& .list--highlight {
							.cmp-list {
								&__item-link {
									font-weight: bold;
									color: $color-link;
									font-size: 18px;

									@media screen and (max-width:$tablet-large) {	
										color: $color-lighter-black;
									}
								}
							}
						}
	
						& .text--subtitle {
							.cmp-text {
								border-bottom: none;
	
								p {
									margin-bottom: 8px;
									font-size: 16px;
								}
							}
						}
	
						& .list + .text--subtitle {
							margin-top: 24px;
						}
					}
				}
			} 

			+ .navigation_trigger {
				@media screen and (max-width:$tablet-large) {
					padding-top: 32px;
				}
			}
		}
	}
}
