.text {
	&--arrow-bullet {
		.cmp-text {
			ul {
				padding: 0;
				list-style-type: none;
				
				li {

					&::before {
						content: none;
					}

					padding-bottom: 1em;
					padding-top: 2em;
					text-transform: uppercase;
					font-weight: bold;
					border-bottom: 1px solid #ccc;
					display: flex;
					justify-content: space-between;
		
					a {
						text-decoration: none;
						border-bottom: 2px solid transparent;
						transition: 0.5s;
		
						&::before {
							content: ' ➞';
							color: $color-link;
							padding-right: 15px;
							transition: 0.5s;
							text-decoration: none;
						}
		
						&:hover {
							border-bottom-color: $color-accent;
		
							&::before {
								color: $color-accent;
							}
						} 
					}
				}
		
				@media screen and (min-width: $tablet) {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					justify-content: space-between;
		
					li {
						width: calc(50% - 48px);
					}
				}
			}
		}

		&.one-row {
			.cmp-text {
				ul {
					@media screen and (min-width: $tablet) {
						li {
							width: 100%;
						}
					}
				}
			}
		}
	}

	&-link {
		&--none {
			.cmp-text {
				a {
					color: $color-accent;
					text-decoration-color: transparent;

					&:hover {
						color: $color-accent-dark;
						text-decoration-color: $color-accent-dark;
					}
				}
			}
		}

		&--red {
			.cmp-text {
				a {
					color: $color-accent;
					text-decoration-color: $color-accent;

					&:hover {
						color: $color-accent-dark;
						text-decoration-color: $color-accent-dark;
					}
				}
			}
		}

		&--teal {
			.cmp-text {
				a {
					color: $color-link-dark;
					text-decoration-color: transparent;

					&:hover {
						color: $color-link-dark;
						text-decoration-color: $color-accent;
					}
				}
			}

		}

		&--white {
			.cmp-text {
				a {
					color: $color-text-white;
					text-decoration-color: $color-text-white;

					&:hover {
						color: $color-text-white;
						text-decoration-color: $color-accent;
					}
				}
			}

		}

		&--black {
			.cmp-text {
				a {
					color: $color-text;

					&:hover {
						text-decoration-color: $color-accent;
					}
				}
			}

		}

		&--arrow {
			.cmp-text {
				position: relative;
				
				a {
					&:after {
						content: '\2199';
						position: absolute;
						top: 0;
						right: -28px;
						transition: 0.5s;
						transform: rotate(225deg);
						color: $color-link;
						transition: 0.3s;
					}
		
					&:hover {
						&:after {
							color: $color-accent;
						}
					}
				}
			}
		}
	}
}