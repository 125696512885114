.list {
	&--2-columns {
		.cmp-list {
			@media screen and (min-width: $tablet) {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: space-between;

				.cmp-list__item {
					width: calc(50% - 96px);
				}
            }
		}
	}

	&--3-columns {
		.cmp-list {
			@media screen and (min-width: $tablet) {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: space-between;

				.cmp-list__item {
					width: calc(33.33% - 96px);
				}
            }
			
		}
	}

	&--line {
		.cmp-list {
			&__item {
				padding-bottom: 1.5em;
				padding-top: 1.5em;
				padding-left: 48px;
				border-bottom: 1px solid $color-light-grey;
				margin: 0;

				&-link {
					text-decoration: none;
					position: relative;
					justify-content: space-between;
					background: transparent;

					&:after {
						top: 0;
						left: -35px;
						right: unset;
					}
				}
			}
		}
	}

	&--article {
		.cmp-list {
			padding: 0;

			&__content {
				display: flex;
			}

			&__item {
				display: flex;
				
				&:not(:last-child) {
					padding-bottom: 32px;
				}

				&-description {
					margin-top: 24px;

					&:empty {
						display: none;
					}
				}

				&-link {
					margin: 0;
					width: 100%;
					
					.cmp-list__item-date {
						display: none;
					}

					&:after {
						display: none;
					}
				}

				&--date {
					padding-right: 8px;
					font-size: 16px;
					color: $color-link-dark;
					flex: 1;
					border-right: 2px solid $color-shade-1;
					display: flex;
					justify-content: center;
					
					.date {
						margin: 20px 0;
					}
				}

				&--text {
					display: flex;
					flex-direction: column;
					flex: 6;
					padding: 16px 0 16px 24px;
				}
			}
		}
	}
}