.image {
	&--logo {
		.cmp-image {
			display: flex;
			height: 100%;
			width: 100%;
			justify-content: center;
			margin: 0 auto;
			align-items: center;

			&__link {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100%;
				width: 100%;
			}

			img {
				max-height: 90px;
				max-width: 90px;
				object-fit: contain;
				width: 100%;
				padding: 16px;
				border-radius: 15px;
				height: auto;
				background: white;
				height: 100%;
			}
		}
	}

	&--horizontal {
		&-center {
			height: 100%;

			.cmp-image {
				margin: 0;
				height: 100%;
				display: flex;
				align-items: center;
			}
		}
	}
	
}
