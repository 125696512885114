.container {
    .cmp-container {
        margin-top: 2em;

        .shape--teal {
            margin-bottom: -3em;
        }
    }

    #publications > div {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        //align-items: start;

        .teaser.textAtBottom {
            width: 25%;
            margin-bottom: 2em;
            @media screen and (max-width: $mobile) {
                width: 100%;
            }
            @media screen and (min-width: $mobile) and (max-width: $tablet) {
                width: 50%;
            }            

            .cmp-teaser {
                height: 100%;

                &__image {
                    height: 283px; //height of publicationthumbnail fatf
                    width: 200px; //width of publication thumbnail fatf
                    align-self: center;
                }

                &__content {
                    margin-left: 1.5em;
                    margin-right: 1.5em;
                    flex-grow: 1;
                }

                &__title {
                    font-size: 1em;
                    line-height: 1.25em;
                }

                &__description {
                    flex-grow: 1;
                }
            }
        }
    }
}

