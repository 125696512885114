.cmp-list {
	padding-left: 24px;

	&__item {
		list-style-type: none;
		text-decoration: none;
		padding: 8px 0;

		&-link {
			text-decoration: none;
			position: relative;
			display: inline-block;
			margin-right: 40px;

			&:after {
				content: '\2199';
				position: absolute;
				top: 0;
				right: -28px;
				transition: 0.5s;
				transform: rotate(225deg);
				color: $color-link;
				transition: 0.3s;
			}

			&:hover {
				.cmp-list__item-title {
					text-decoration-color: $color-accent;
				}

				&:after {
					color: $color-accent;
				}
			}
		}

		&-title {
			position: relative;
			text-decoration: underline;
			text-decoration-color: transparent;
			transition: 0.5s;
			text-decoration-thickness: 2px;
		}
	}
}