.cmp-button {
	background-color: $color-accent;
	color: white;
	text-decoration: none;
	padding: 8px 24px;
	border: 2px solid $color-accent;
	border-radius: 10rem;
	cursor: pointer;
	transition: 0.5s;

	&__text {
		white-space: nowrap;
		overflow: hidden;
		position: relative;
		transition: 0.5s;

		&:after {
			content: '\00bb';
			position: absolute;
			opacity: 0;
			top: -3px;
			right: -5px;
			transition: 0.5s;
		}
	}

	&:hover {
		background-color: $color-accent-dark;
		border-color: $color-accent-dark;
		padding-left: 18px;

		& .cmp-button__text {
			padding-right: 6px;

			&:after {
				opacity: 1;
				right: -9px;
			}
		}
	}

	&:active {
		background-color: $color-accent-darker;
		border-color: $color-accent-darker;
	}

	&:focus {
		box-shadow: none;
		outline: none;
	}
	
	&:focus-visible {
		background-color: $color-accent;
		border-color: $color-accent;
		outline-offset: 1px;
   		outline: $color-accent-lighter solid 2px;
	}
}

.cmp-button:empty {
	background-color: transparent;
    border-color: transparent;
}
