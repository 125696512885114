.teaser {
	&--smaller {
		.cmp-teaser {
			border-radius: 15px;
			overflow: hidden;

			&__title {
				font-size: 24px;
				line-height: 1.5em;
				font-weight: 400;
			}

			&__description {
				font-weight: 300;
				font-size: 1em;
				line-height: 1.25em;
			}
		}	

		.cmp-image {
			height: 100%;
			overflow: hidden;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 15px;
		}

		.cmp-teaser__action-link {
			&:after {
				top: 6px;
			}
		}

		&.list {
			.cmp-list {
				padding-left: 0;

				&__item {
					.cmp-teaser {
						overflow: hidden;
						border-radius: 15px;
						width: 100%;
						margin: 0;

						&__content {
							padding: 24px;
						}
	
						&__image {
							top: -50%;
							bottom: -50%;
	
							.cmp-image__image {
								position: absolute;
							}
						}
					}
				}
			}
		}

		&[class*=list--] {
			.cmp-list {
				display: flex;
    			justify-content: space-between;
				flex-wrap: wrap;

				&__item {
    				display: flex;

					.cmp-teaser {
						margin-right: 16px;

						@media screen and (max-width: $tablet) {
							margin: 0;
						}
					}
				}
			}
		}

		&.list {
			&--2-columns {
				.cmp-list__item {
					flex: 50%;

					&:nth-child(2n),
					&:last-child {
						.cmp-teaser {
							margin-right: 0;
						}
					}
				}
			}

			&--3-columns {
				.cmp-list__item {
					flex: 33.3%;

					&:nth-child(3n) {
						.cmp-teaser {
							margin-right: 0;
						}
					}
				}
			}

			&--2-columns,
			&--3-columns {
				.cmp-list__item {
					@media screen and (max-width: $tablet) {
						flex: 100%;
					}
				}
			}
		}
	}

	&--red-button {
		.cmp-teaser__action {
			margin-top: 24px;
		
			&-link {
				background-color:  $color-accent;
				border-color:  $color-accent;

				&:hover {
					background-color: $color-accent-dark;
					border-color: $color-accent-dark;
				}

				&:active {
					background-color:  $color-accent-darker;
					border-color:  $color-accent-darker;
				}
			
				&:focus-visible {
					background-color: $color-accent;
					border-color: $color-accent;
				}
		
				&:not(:first-child) {
					background-color: transparent;
					color: $color-accent;
		
					&:hover, 
					&:active {
						border-color: $color-accent;
					}
		
					&:hover {
						background-color: transparent;
					}
		
					&:active {
						background-color: $color-accent-a5;
					}
				}
			}
		}
	}

	&--no-image {
		.cmp-teaser {
			&__image {
				display: none;
			}

			&__content {
				padding: 0;
			}
		}
	}

	&--text-top,
	&--text-bottom,
	&--text-right,
	&--text-left {
		.cmp-teaser {
			display: flex;

			&__link {
				display: flex;
			}

			&__image {
				border-radius: 15px;
				overflow: hidden;
				position: relative;

				& img {
					top: 0;
					left: 0;
					display: block;
					width: 100%;
					height: 100%;
					object-fit: cover;

					@media screen and (max-width: 768px) {
						max-height: 300px;
					}
				}
			}		
		}
	}

	&--text-top,
	&--text-bottom,
	&--text-right,
	&--text-left,
	&--no-image {
		.cmp-teaser {
			&__content {
				top: 0;
				left: 0;
				transform: none;
				margin: 0;
			}	

			&__pretitle {
				margin: 0;
				color: $color-shade-3;
				font-weight: 300;
				font-size: 0.625em;
				line-height: 1.125rem;
				letter-spacing: 0.025rem;
				text-align: start;
				text-transform: uppercase;
				margin-bottom: 0;
			}

			&__title {
				margin-top: 0.625rem;
				margin-bottom: 1.1875rem;
				color: $color-text;
				font-family: $font-family;
				font-size: 24px;
				line-height: 1.5em;
				font-weight: bold;
				text-align: start;
				text-decoration-color: transparent;

				&-link {
					color: $color-text;
					text-decoration: underline;
					text-decoration-color: transparent;
					transition: 0.7s;

					&:hover {
						text-decoration-color: $color-accent;
					}
				}
			}

			&__description {
				color: $color-text;
				font-size: 1em;
				line-height: 1.25em;
				font-weight: 400;
				text-align: start;

				p {
					margin-top: 0;
				}
			}
			
			&__date {
				color: $color-text;
			}

			&__action {
				&-container {
					display: flex;
					justify-content: start;
					margin: 1rem 0;
					padding: 0.125rem;

					&-link {
						margin-right: 0.8em;
						padding: 0.5625em 1em 0.6875em 1em;
						color: $color-text-inverted;
						font-weight: 300;
						text-decoration: none;
						border-radius: 1.56em;

						&:first-child {
							background-color: $color-accent;
							border: 0.125em solid $color-accent;
						
							&:hover {
							background-color: $color-accent-darker;
							border-color: $color-accent-darker;
							}
							&:focus {
							background-color: $color-accent-lighter;
							border-color: $color-background;
							outline: none;
							box-shadow: 0 0 0 0.125em $color-accent-lighter;
							}
							&:disabled {
							background-color: $color-shade-1;
							border-color: $color-shade-1;
							}
						}

						&:not(:first-child) {
							color: $color-shade-4;
							background-color: rgba(0, 0, 0, 0);
							border: 0.125em solid $color-shade-4;
						
							&:hover {
							color: $color-text-inverted;
							background-color: $color-shade-4;
							}
							&:focus {
							color: $color-shade-4;
							background-color: $color-background;
							border: 0.125em solid $color-shade-4;
							outline: none;
							box-shadow: none;
							}
							&:disabled {
							color: $color-shade-1;
							border-color: $color-shade-1;
							}
						}
					}
				}
			}
		}
		&:not(.teaser--red-button) {
			.cmp-teaser {
				&__action {
					&-link {
						&:not(:first-child) {
							color: $color-link;
							border-color: $color-link;
			
							&:hover, &:active {
								border-color: $color-link;
							}
							&:active {
								background-color: $color-link-a15;
							}
						}
					}
				}
			}
		}
	}

	&--background {
		&-teal,
		&-grey,
		&-red {
			&.teaser {
				&--no-image {
					.cmp-teaser {		  
						&__content {
							padding: 1rem;
							border-radius: 15px;
						}
					}	
				}

				&--text-top {
					.cmp-teaser {	
						&__image {
							border-top-left-radius: 0;
							border-top-right-radius: 0;
						}

						&__content {
							border-top-left-radius: 15px;
							border-top-right-radius: 15px;
						}
					}	
				}

				&--text-bottom {
					.cmp-teaser {	
						&__image {
							border-bottom-left-radius: 0;
							border-bottom-right-radius: 0;
						}

						&__content {
							border-bottom-left-radius: 15px;
							border-bottom-right-radius: 15px;
						}
					}	
				}

				&--text-right {
					.cmp-teaser {	
						&__image {
							border-bottom-right-radius: 0;
							border-top-right-radius: 0;
						}

						&__content {
							border-bottom-right-radius: 15px;
							border-top-right-radius: 15px;
							padding: 1rem 2rem;
						}
					}	
				}

				&--text-left {
					.cmp-teaser {	
						&__image {
							border-bottom-left-radius: 0;
							border-top-left-radius: 0;
						}

						&__content {
							border-bottom-left-radius: 15px;
							border-top-left-radius: 15px;
							padding: 1rem 2rem;
						}
					}	
				}

				&--text-right,
				&--text-left {
					.cmp-teaser {	
						&__image {
							@media screen and (max-width: 768px) {
								border-radius: 15px;
								border-bottom-left-radius: 0;
								border-bottom-right-radius: 0;
							}
						}

						&__content {
							@media screen and (max-width: 768px) {
								border-radius: 0;
								border-bottom-left-radius: 15px;
								border-bottom-right-radius: 15px;
							}
						}
					}	
				}
			}	
		}


		&-teal {
			&.teaser {
				&--text-top,
				&--text-bottom,
				&--text-right,
				&--text-left,
				&--no-image {
					.cmp-teaser {		  
						&__content {
							background-color: $color-link-light;
						}
					
						&__pretitle {
							color: $color-shade-4;
						}
					
						&__title {
							color: $color-text;
					
							&-link {
								color: $color-text;

								&:hover {
									color: $color-text;
								}
							}
						}
					
						&__description,
						&__date {
							color: $color-text;
						}
					}	
				}
			}		  
		}

		&-grey {
			&.teaser {
				&--text-top,
				&--text-bottom,
				&--text-right,
				&--text-left,
				&--no-image {
					.cmp-teaser {		  
						&__content {
							background-color: $color-light-background;
						}
					}	
				}
			}		  
		}

		&-red {
			&.teaser {
				&--text-top,
				&--text-bottom,
				&--text-right,
				&--text-left,
				&--no-image {
					.cmp-teaser {		  
						&__content {
							background-color: $color-accent;
						}

						&__pretitle {
							color: $color-text-white;
						}
					
						&__title {
							color: $color-text-white;
					
							&-link {
								color: $color-text-white;

								&:hover {
									color: $color-text-white;
								}
							}
						}
					
						&__description {
							color:$color-text-white;
							
							a {
								color:$color-text-white;
							}
						}
						
						&__date {
							color:$color-text-white;
						}

						&__action {						
							&-link {
								background-color: $color-background;
								border-color: $color-background;
								color: $color-text;
				
								&:hover {
									background-color: $color-background;
									border-color: $color-background;
									color: $color-text;
								}
				
								&:active {
									background-color: $color-light-grey;
									border-color:  $color-light-grey;
									color: $color-text;
								}
							
								&:focus-visible {
									background-color: $color-background;
									border-color: $color-background;
									color: $color-text;
								}

								&:not(:first-child) {
									background-color: $color-background;
									border-color: $color-background;
									color: $color-text;
					
									&:hover {
										background-color: $color-background;
										border-color: $color-background;
										color: $color-text;
									}
					
									&:active {
										background-color: $color-light-grey;
										border-color:  $color-light-grey;
										color: $color-text;
									}
								
									&:focus-visible {
										background-color: $color-background;
										border-color: $color-background;
										color: $color-text;
									}
								}
							}
						}
					}	
				}
			}		  
		}
	}

	&--text-top,
	&--text-bottom,
	&--text-right,
	&--text-left {
		&.teaser--contain-image {
			&--mobile {
				.cmp-teaser {
					&__image {
						& img {
							@media screen and (max-width: 768px) {
								max-height: 300px;
								object-fit: contain;
								height: fit-content;
							}
						}
					}		
				}
			}

			.cmp-teaser {
				&__image {
					& img {
						object-fit: contain;
					}
				}		
			}

			&:not([class*=teaser--background-]) {
				@media screen and (max-width: 768px) {
					.cmp-teaser {
						&__image {
							& img {
								border-radius: 15px;
								overflow: hidden;
								width: fit-content;
								position: relative;
								margin: 0 auto;
							}
						}		
					}
				}
			}

			&.teaser--background {
				&-teal {
					.cmp-teaser {
						&__image {
							& img {
								background-color: $color-link-light;
							}
						}		
					}
				}

				&-grey {
					.cmp-teaser {
						&__image {
							& img {
								background-color: $color-light-background;
							}
						}		
					}
				}
				
				&-red {
					.cmp-teaser {
						&__image {
							& img {
								background-color: $color-accent;
							}
						}		
					}
				}
			}
		}
	}
	&--text-top,
	&--text-bottom {
		&.teaser--contain-image {
			&:not([class*=teaser--background-]) {
				.cmp-teaser {
					&__image {
						& img {
							border-radius: 15px;
							overflow: hidden;
							width: fit-content;
							position: relative;
							margin: 0 auto;
						}
					}		
				}
			}
		}
	}
}

.teaser {
	&.teaser--smaller {
		.cmp-teaser {
			&__image {
				height: 100%;
			}
		}
	}
}