.cmp-teaser {
	position: relative;
	display: flex;
	flex-direction: column-reverse;
	justify-content: center;
	margin: 16px 0;
	z-index: 0;
	position: relative;

	&__image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		
		.cmp-image {
			margin: 0;
			height: 100%;
		}
		img {
			display: block;
			height: 100%;
			object-fit: cover;
		}
	}

	&__link {
		text-decoration: none;
		width: 100%;
		position: relative;
		display: flex;
		flex-direction: column-reverse;
		justify-content: center;
		margin: 16px 0;
		position: relative;

		.cmp-teaser__title {
			transition: 0.5s;
			text-decoration-color: transparent;
		}

		&:hover {
			.cmp-teaser__title {
				text-decoration: underline;
				text-decoration-color: $color-accent;
			}
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		padding: 15px;
		z-index: 1;

		&:only-child {
			position: relative;
			top: 0;
			left: 0;
			transform: none;
		}
	}

	&__pretitle {
		display: none;
	}

	&__title {
		margin-top: 0.5625rem;
		margin-bottom: 1rem;
		color: #ffffff;
		font-size: 2.25em;
		line-height: 3.0625rem;
		text-decoration: none;

		&-link {
			color: #ffffff;
			font-weight: bold;
			text-decoration: none;
		}
	}

	&__description {
		color: #ffffff;
		font-size: 24px;
	}

	&__date {
		color: #ffffff;
	}

	&__action {
		&-container {
			margin-top: 16px;
			margin-bottom: 8px;
			flex-wrap: wrap;

			@media screen and (max-width: $tablet) {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
			}
		}

		&-link {
			background-color: $color-link-dark;
			border: 2px solid  $color-link-dark;
			color: white;
			text-decoration: none;
			padding: 8px 24px;
			border-radius: 10rem;
			cursor: pointer;
			transition: 0.5s;
			white-space: nowrap;
			overflow: hidden;
			position: relative;
			transition: 0.5s;
			margin-top: 4px;
    		margin-bottom: 4px;

			&:after {
				content: '\00bb';
				position: absolute;
				opacity: 0;
				top: 8px;
				right: 20px;
				transition: 0.5s;
			}

			&:hover {
				padding-right: 30px;
				padding-left: 18px;
				background-color: $color-link-darker;
				border-color: $color-link-darker;

				&:after {
					opacity: 1;
					right: 12px;
				}
			}

			&:active {
				background-color:  $color-link-darkest;
				border-color:  $color-link-darkest;
			}
		
			&:focus {
				box-shadow: none;
				outline: none;
			}
			
			&:focus-visible {
				background-color: $color-link-dark;
				border-color: $color-link-dark;
				outline-offset: 1px;
				outline: $color-accent-lighter solid 2px;
			}

			&:not(:first-child) {
				background-color: transparent;
				color: $color-text-white;
				border-color: $color-background;

				&:hover, &:active {
					border-color: $color-text-white;
				}

				&:hover {
					background-color: transparent;
				}

				&:active {
					background-color: $color-white-a15;
				}

				&:disabled {
					color: $color-shade-1;
					border-color: $color-shade-1;
				}

				@media screen and (max-width: $tablet) {
					margin-left: 0;
				}
			}
			
			&:not(:last-child) {
				margin-right: 24px;
			}
		}
	}
}

